import './AdminLists.scss'
import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Button, Icon, Input, message, Modal, notification, Pagination, Select, Spin, Switch, Table, Upload} from 'antd'
import apiConfig from '../../config/api'
import {changeAllowNegClientPromise, getNegativeListPromise, updateNegativeRecordPropmise} from './promises'
import {ReportService, SessionStorageService} from '../../services'
import {globalContext} from '../../contexts'
import {t} from "i18next";
const { confirm } = Modal;

const resultsPerPage = 10;
const { Option } = Select

const emptyFilters = {
  rut: '',
  paterno: '',
  materno: '',
  nombre: '',
  causal: '',
  riesgo: ''
};

class AdminLists extends Component {
  static contextType = globalContext

  state = {
    isDownloading: false,
    isLoading: true,
    isModalUploadVisible: false,
    negativeList: [],
    allowNegAdm: this.props.currentUser.cliente.allowNegAdm,
    resultsCurrentPage: 1,
    resultsTotalNum: -1,
    reportId: null,
    intervalReportId: null,
    filters: emptyFilters
  }

  async getFirstSearchResults() {
    this.setState({ isModalUploadVisible: false })
    this.handlePaginationOnChange(1)
  }

  async handlePaginationOnChange(currentPage) {
    const fromNum = ((currentPage - 1) * resultsPerPage)
    const {filters} = this.state;

    await this.setState({ isLoading: true, searchResultsFromNum: fromNum })

    filters.size = resultsPerPage
    filters.from = fromNum

    const negativeList = await getNegativeListPromise(filters)

    await this.setState({
      isLoading: false,
      negativeList: negativeList.records,
      resultsCurrentPage: currentPage,
      resultsTotalNum: negativeList.total
    })
  }

  async handleSubmitPagination(currentPage) {
    this.handlePaginationOnChange(currentPage)
  }

  async componentDidMount() {
      this.getFirstSearchResults()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filters !== this.state.filters) {
      this.handlePaginationOnChange(1, this.state.filters);
    }
  }

  handleFilterChange(filterKey, value) {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          [filterKey]: value
        },
      }))
  }

  async handleChangeRisk(record, selectedValue) {

    record.riesgoAsignado = selectedValue;
    const changeRiskOK =() => {
      message.success("Registro actualizado")
      setTimeout(() => {
        this.handlePaginationOnChange(this.state.resultsCurrentPage);
      }, 2500);
    }

    confirm({
      title: 'Modificará el riesgo del registro, desea continuar?',
      okText: "Si",
      cancelText: "No",
      async onOk() {
        const recordUpdate = await updateNegativeRecordPropmise([record]);
        if (recordUpdate === "OK") {
          changeRiskOK()
        } else {
          message.error("Ha ocurrido un error al actualizar registro")
        }
      },
      onCancel() {
        return;
      },
    });
  }

  clearFilters() {
    this.setState({ filters: emptyFilters }, () => {
      this.handlePaginationOnChange(1);
    });
  }

  selectedRiskFilter() {
    const { filters } = this.state;
    if (filters.riesgo === "") {
      return t('messages.aml.assignedRisk');
    } else if (filters.riesgo === "N") {
      return "SIN RIESGO";
    } else {
      return filters.riesgo;
    }
  }

  renderTableColumns() {
    const { t } = this.props
    const { filters } = this.state;

    const columns = [
      {
        title: <Input
                  placeholder={t('messages.aml.rutNumber')}
                  value={filters.rut}
                  onChange={(e) => this.handleFilterChange('rut', e.target.value)}
        />,
        dataIndex: 'rut'
      },
      {
        title: <Input
                  placeholder={"A. paterno"}
                  value={filters.paterno}
                  onChange={(e) => this.handleFilterChange('paterno', e.target.value)}
        />,
        dataIndex: 'paterno'
      },
      {
        title: <Input
                  placeholder={"A. materno"}
                  value={filters.materno}
                  onChange={(e) => this.handleFilterChange('materno', e.target.value)}
        />,
        dataIndex: 'materno'
      },
      {
        title: <Input
                  placeholder={t('messages.aml.name')}
                  value={filters.nombre}
                  onChange={(e) => this.handleFilterChange('nombre', e.target.value)}
        />,
        dataIndex: 'nombre'
      },
      {
        title: <Input
                  placeholder={t('messages.aml.causal')}
                  value={filters.causal}
                  onChange={(e) => this.handleFilterChange('causal', e.target.value)}
        />,
        dataIndex: 'causal'
      },
      {
        title: t('messages.aml.startingDate'),
        dataIndex: 'fechaInicio'
      },
      {
        title: t('messages.aml.endingDate'),
        dataIndex: 'fechaFin'
      },
      {
        title: <Select
            placeholder={t("messages.aml.assignedRisk")}
            value={this.selectedRiskFilter()}
            onChange={(value) => this.handleFilterChange('riesgo', value)}
            style={{width: "100%"}}
        >
            <Option key="N" value="N" className="optionRisk"> {t("messages.aml.no.risk")} </Option>
            <Option key="" value="" className="optionRisk"> {"TODOS"} </Option>
            {this.insideRiskColor.map(({name, color}) => (
              <Option key={name} value={name} className="optionRisk">
                <div style={{width:'100%'}}>
                  <span>{name}</span> &nbsp;&nbsp;
                  <span
                      className="riskSelector"
                      style={{
                        backgroundColor: color,
                        borderRadius: '50%',
                        display: 'inline-block',
                        marginRight:'5px'
                      }}
                  ></span>
                </div>
              </Option>
          ))}


        </Select>,
        dataIndex: 'assignedRisk',
        render: (text, record) => {
          return (
              <Select
                  placeholder={t('messages.aml.assignedRisk')}
                  value={record.riesgoAsignado}
                  style={{width:'100%'}}
                  onChange={(value) => this.handleChangeRisk(record, value)}
                  allowClear
              >
                {this.insideRiskColor.map(({name, color}) => (
                    <Option key={name}
                            value={name}
                            className="optionRisk">
                      <div style={{width:'100%'}}>
                        <span>{name}</span> &nbsp;&nbsp;
                        <span
                            className="riskSelector"
                            style={{
                              backgroundColor: color,
                              borderRadius: '50%',
                              display: 'inline-block',
                              marginRight:'5px'

                            }}
                        ></span>
                      </div>
                    </Option>
                ))}
              </Select>
          )

        },
      },
    ]

    return columns
  }

  insideRiskColor = [
    {name: 'BLACK', color: '#000000FF'},
    {name: 'RED', color: '#ee220c'},
    {name: 'ORANGE', color: '#ff9300'},
    {name: 'YELLOW', color: '#fae232'},
    {name: 'GREEN', color: '#598756'},
  ];

  handleUploadClick() {
    this.setState({isModalUploadVisible: true})
  }

  async handleDownload() {
    await this.setState({isDownloading: true})
    await ReportService.read('/excelListasPropias', null, null, 'ListasPropias.xlsx')
    await this.setState({isDownloading: false})

  }

  async changeAllowNegAdm(checked) {
    const { t } = this.props
    let result = await changeAllowNegClientPromise(checked)
    if(result.status === 'OK') {
      this.setState({ allowNegAdm: checked })
      notification.success({
        message: t('messages.aml.successfulOperation'),
        description: checked ? t('messages.aml.permissionGranted') : t('messages.aml.permissionDeny')
      })
    }else {
      notification.error({
        message: t('messages.aml.anErrorOcurred'),
        description: result.message
      })
    }
  }

  render() {
    const { t } = this.props
    const { isDownloading, isLoading, isModalUploadVisible, negativeList, allowNegAdm, resultsCurrentPage, resultsTotalNum } = this.state
    const authToken = SessionStorageService.read('authToken')

    const propsUpload = {
      accept: ".xlsx,.txt",
      name: 'file',
      multiple: false,
      headers: {
        Authorization: 'Bearer ' + authToken
      },
      action: apiConfig.url + '/uploadNeg',
      onChange(info) {
        const { status } = info.file

        if (status !== 'uploading') {
        }

        if (status === 'done') {
          notification.success({
            message: t('messages.aml.successfulOperation'),
            description: t('messages.aml.fileUploadedSuccesfully')
          })
        } else if (status === 'error') {
          notification.error({
            messages: t('messages.aml.anErrorOcurred'),
            description: t('messages.aml.anErrorOcurredWhileUploadingFile')
          })
        }
      }
    }

    const { Dragger } = Upload

    return (
      <div className="admin-lists">

        <div className="tools-area">
          <div style={{ float: 'left', color:'#fff' }}>
            <Switch checked={ allowNegAdm } onChange={ (checked) => this.changeAllowNegAdm(checked) }/>&nbsp;&nbsp;Admin. Gesintel
          </div>

          <Button type="primary" icon={ isDownloading ? 'loading' : 'cloud-download' } disabled={isDownloading} onClick={ this.handleDownload.bind(this) }>{ t('messages.aml.download') }</Button>&nbsp;
          <Button type="primary" icon="upload" onClick={ this.handleUploadClick.bind(this) }>{ t('messages.aml.uploadFile') }</Button>&nbsp;
          <Button type="primary" icon="close" onClick={ this.clearFilters.bind(this) }>{ t('messages.aml.clearAllFilters') }</Button>

        </div>

        <div className="table-wrapper">
          { isLoading ?
              <Spin spinning="true" size="large" />
              :
              <Table
                  dataSource={ negativeList }
                  columns={ this.renderTableColumns() }
                  pagination={ false } size="small" />
          }
        </div>

        <Pagination current={ resultsCurrentPage } total={ resultsTotalNum } onChange={ this.handleSubmitPagination.bind(this) } pageSize={ resultsPerPage } size="small" />
        <Modal
          onCancel={ () => this.setState({ isModalUploadVisible: false })}
          onOk={ this.getFirstSearchResults.bind(this) }
          visible={ isModalUploadVisible }
          title={ t('messages.aml.uploadFile') }
          key={ Date.now() }
        >

        <Dragger {...propsUpload} >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">{ t('messages.aml.uploadFileInstructions') }</p>
        </Dragger>
      </Modal>
      </div>
    )
  }

}

export default withTranslation()(AdminLists)
