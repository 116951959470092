import './ModalIndexCard.scss'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Row, Col, Input, Modal, Button, Icon, Select, message, List, Radio, Upload, Table, Divider, Card } from 'antd'
import { ModalPdfViewer } from '../../../../../InterestConflicts/components'
import { useTranslation } from 'react-i18next'
import { getFormPromise, saveStatePromise, saveCommentsPromise } from '../../promises'
import { HistoryModal } from '../../../../../../layouts/Private/components'
import { ReportService } from '../../../../../../services'
import { camelizerHelper } from '../../../../../../helpers'

const ModalIndexCard = ({item, handleCancel}) => {

  const [seeMore1, setSeemore1] = useState(false);
  const [seeMore2, setSeemore2] = useState(false);
  const [seeMore4, setSeemore4] = useState(false);
  const [seeMore5, setSeeMore5] = useState(false);
  const [seeMore6, setSeeMore6] = useState(true);
  const [itemTable, setItemTable] = useState(item);
  const [formId, setFormId] = useState(null);
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [statusComments, setStatusComments] = useState(null);
  const [comments, setComments] = useState("");
  const [title, setTitle] = useState(null);
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [lastCommentDate, setLastCommentDate] = useState(null);
  const [ resendOnBoardingForm, setResendOnBoardingForm ] = useState(false);
  const [ showAttachments, setShowAttachments] = useState(null);
  const [ userFileName, setUserFileName] = useState("");
  const [ fileNames, setFileNames] = useState([]);
  const [ tmpFilesList, setTmpFilesList] = useState([]);
  const [ loadingAttch ,setLoadingAttch ] = useState(false)

  useEffect(() => {
    getFormPromise(item.id).then((response) => {
      setItemTable(response.data);
      if(response.data?.relations?.length > 0) {
        setSeeMore5(true)
      }
      if(response.data.histComments.length > 0){
        setLastCommentDate(moment(response.data.histComments[0].creationDate).format('DD/MM/YYYY HH:mm'));
      }
    })
  }, []);


  const handleMore1 = () => {
    if (seeMore1 === false){
      setSeemore1(true)
    }else
      setSeemore1(false)
  }

  const handleMore2 = () => {
    if (seeMore2 === false){
      setSeemore2(true)
    }else
      setSeemore2(false)
  }

  const handleMore4 = () => {
    if (seeMore4 === false){
      setSeemore4(true)
    }else
      setSeemore4(false)
  }

  const renderTypeDeclarationInputs = () => {
    let temp = []

    if (item.type === 'GIFT'){
      temp.push(
          {
            label: 'Empresa que regala',
            data: itemTable?.reunion?.organism
          },
          {
            label: 'Tipo de regalo',
            data: itemTable?.reunion?.type
          },
          {
            label: 'Valor estimado',
            data: itemTable?.reunion?.value
          }
        )
    }
    if (item.type === 'TRAVEL'){
      temp.push(
        {
          label: 'Empresa que regala',
          data: itemTable?.reunion?.organism
        },
        {
          label: 'Destino',
          data: itemTable?.reunion?.place
        },
        {
          label: 'Valor estimado',
          data: itemTable?.reunion?.value
        }
      )
    }
    if (item.type === 'FP'){
      temp.push(
        {
          label: 'Nombre del funcionario',
          data: itemTable?.recipient?.record?.nombre
        },
        {
          label: 'Tipo de reunión',
          data: itemTable?.reunion?.type
        },
        {
          label: 'Fecha de la reunión',
          data: moment(itemTable?.reunion?.date).format('DD/MM/YYYY')
        }
      )
    }
    if (item.type === 'SOC'){
      temp.push(
        {
          label: 'Nombre del funcionario',
          data: itemTable?.recipient?.record?.nombre
        },
        {
          label: 'Fecha de la reunión',
          data: moment(itemTable?.reunion?.date).format('DD/MM/YYYY')
        },
        {
          label: 'Nombre de la asociación',
          data: itemTable?.reunion?.summary
        }
      )
    }
    return temp
  }

  const varStateOptions = (actualState) => {
    var options = [];
    if (actualState === 'EVALUACION'){
      options.push({val: "AUTORIZADA", label: "Autorizado"}, {val: "RECHAZADA", label: "Rechazado"});
    }else if (actualState === 'AUTORIZADA'){
      options.push({val: "EVALUACION", label: "Realizado"}, {val: "RECHAZADA", label: "Rechazado"});
    }else if(actualState === 'RECHAZADA'){
        options.push({val: "EVALUACION", label: "Realizado"}, {val: "AUTORIZADA", label: "Autorizado"});
    }
    return options;
  }

  const handlePdfViewer = (record) => {
    setFormId(record.id);
  }

  const onCancelPdfViewer = () => {
    setFormId(null);
  }

  const handleSaveStatus = () => {
      saveStatePromise(item.id, selectedStatus, statusComments, resendOnBoardingForm).then((response) => {
        if(response.success === true){
          message.success("Se ha guardado el estado de la declaración correctamente");
          handleCancel();
        }else{
        message.error("Ha ocurrido un error al guardar el estado de la declaración")
        }
      })
  }

const handleSaveAttachment = () => {
  const formData = new FormData()
    formData.append( 'formId', item.id)
    formData.append('title', title)
    formData.append('comments', comments)
    saveCommentsPromise(formData).then((response) => {
      if(response.success === true){
        message.success("Se ha guardado el comentario")
        setItemTable(response.data)
        setLastCommentDate(moment(response.data.histComments[0].creationDate).format('DD/MM/YYYY HH:mm'));
        setComments(null);
        setTitle(null);
      }else{
        message.error("Ha ocurrido un error al guardar el comentario")
        setLoadingAttch(false)
      }
    })
  }

  const handleOnChangeStatus = (value) => {
    setSelectedStatus(value);
  }

  const handleCommentsStateChange = (comment) => {
    setStatusComments(comment);
  }

  const handleTitle = (title) => {
    setTitle(title);
  }

  const handleComments = (comment) => {
    setComments(comment);
  }

  // const handleSaveComment = () => {

  //   if(title){
  //   setShowAttachments({id: item.id, title, comments})
  //   }else{
  //     message.error("Debe ingresar Título")
  //   }
  // }

  const relationColumn = (rowRecord) => {
    return [
      {
        title: 'Documento',
        dataIndex: 'rutRelated',
        width: '14%'
      },
      {
        className: 'relatedTitle',
        title: 'Declaración consultada (Folio: '+itemTable?.folio+') y/o Registros',
        width: '43%',
        render: (record) => {
          return (
            <Row gutter={[0,8]}>
              <Col span={24}>
                {record.sectionOrig ? record.sectionOrig : 'Identificado en el Registro de AMLupdate'}
              </Col>
              <Col span={8}>
                {record.relationOrig}
              </Col>
              <Col span={8}>
                {record.nameOrig}
              </Col>
              <Col span={8}>
                {record.companyOrig}
              </Col>
            </Row>
          )
        }
      },
      {
        title: rowRecord.formRelated && rowRecord.relations?.filter(r => r.sectionRel).length > 0 ? ('Declaración vinculada (Folio: '+rowRecord.formRelated?.folio+')') : 'Registros',
        className: 'vinculadaTitle',
        width: '43%',
        render: (record) => {
          return (
            <Row gutter={[0,8]}>
              <Col span={24}>
                {record.sectionRel ? record.sectionRel : 'Identificado en el Registro de AMLupdate'}
              </Col>
              <Col span={8}>
                {record.relationRel}
              </Col>
              <Col span={8}>
                {record.nameRel}
              </Col>
              <Col span={8}>
                {record.companyRel}
              </Col>
            </Row>
          )
        }
      }
    ]
  }

  const tableColumns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      render: (text) => {
        return camelizerHelper(text)
      }
    },
    {
      title: 'Rut',
      dataIndex: 'rut'
    },
    {
      title: 'Categoría',
      dataIndex: 'category',
      render: (text, record) => t('messages.aml.category.' + text)
    },
    {
      title: 'Folio',
      dataIndex: 'formRelated',
      render: (text, record) => {
        return record && record.formRelated ? record.formRelated.folio : '-'
      }
    },
    {
      title: 'Fecha completado',
      dataIndex: 'formRelated.receiveDate',
      render: (text, record) => {
        return record.formRelated ? moment(text).format('DD/MM/YYYY') : '-'
      }
    },
    {
      title: 'Ver declaración',
      render: (text, record) => record.formRelated && <Button onClick={() => setFormId(record.formRelated.id)} icon="file-pdf" size='small'></Button>
    }
  ]

  const handleGetFile = async (originalName) => {
    const obj = {
      formId: item.id,
      fileName: originalName,
    }
    await ReportService.read('/getFileForm', obj, null, originalName)
  }

  const handleCloseModals = () => {
    setIsHistoryVisible(false);
  }

  const commentSchema = [
    {
      title: 'Fecha',
      dataIndex: 'creationDate',
      cols: 4
    },
    {
      title: 'Nombre',
      dataIndex: 'userName',
      cols: 8
    },
    {
      title: 'Titulo',
      dataIndex: 'title',
      cols: 12
    },
  ];

    return (
      <div className="modIndexCard">
        <Row className="titleRow" onClick={handleMore1}>
          <Col span={6}>
            <h3>Información Básica</h3>
          </Col>
          <Col span={4} push={16}>
            <Button type="link" onClick={handleMore1}>Ver más<Icon type={seeMore1 ? "minus" : "plus"}/></Button>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col span={6} >
            Nombre o razón social
            <Input readOnly value={itemTable?.json?.basicInformation?.basicInfo ? itemTable.json.basicInformation.basicInfo.name : item.name}/>
          </Col>
          <Col span={5} offset={1}>
            Tipo de Documento
            <Input readOnly value={itemTable?.json?.basicInformation?.basicInfo ? itemTable.json.basicInformation.basicInfo.tipoDocumento: itemTable?.recipient?.record?.tipoDoc}/>
          </Col>
          <Col span={5} offset={1}>
            Nro. de Documento
            <Input readOnly value={itemTable?.json?.basicInformation?.basicInfo ? itemTable.json.basicInformation.basicInfo.nroDocumento : item.rut} />
          </Col>
          <Col span={5} offset={1}>
            Correo
            <Input readOnly value={itemTable?.recipient?.record?.email ?? item?.email} />
          </Col>
          
        </Row>
        {seeMore1 &&
          <Row className="div-more-info">
            <Col span={7}>
              Empresa
              <Input readOnly value={itemTable.recipient.record.subcliente ? itemTable.recipient.record.subcliente.name : "Sin empresa asignada"}/>
            </Col>
            <Col span={7} offset={1}>
              Área
              <Input readOnly value={itemTable.recipient.record.area}/>
            </Col>
            <Col span={7} offset={1}>
              Tipo de Persona
              <Input readOnly value={itemTable.recipient && itemTable.recipient.record.type === 'Person' ? "Persona Natural" : "Persona Jurídica"} />
            </Col>
            {/* <Col span={7} offset={1}>
              Grupo
              <Input readOnly value={itemTable.recipient.record.grupo ? itemTable.recipient.record.grupo : "Sin grupo asignado"}/>
            </Col> */}
          </Row>
        }
        {/* FIN INFORMACION BASICA */}
          <Row>
            <Col span={9}>
              <h3>Información de la declaración</h3>
            </Col>
            {item.statusDecl !== 'PENDIENTE' &&
            <>
              <Col span={15}>
                <div className="button-box-index">
                  <Button type="primary" onClick={() => handlePdfViewer(item)}>Ver Declaración<Icon type="file-pdf" /></Button>
                </div>
              </Col>
            </>
            }
              {formId &&
                <Modal
                  className="modal-pdf-viewer"
                  title="Declaración"
                  centered
                  width={1000}
                  header={null}
                  footer={[<Button key="back" onClick={onCancelPdfViewer}>Cerrar</Button>]}
                  onCancel={onCancelPdfViewer}
                  visible="true"
                  ><ModalPdfViewer
                    pdfId={formId}
                  />
                </Modal>
              }
          </Row>
          <hr/>
          {item.statusDecl !== 'PENDIENTE' && (itemTable.gerencia || itemTable.cargo) &&
          <Row gutter={[50]}>
            <Col span={12}>
              Gerencia
              <Input readOnly value={itemTable.gerencia}/>
            </Col>
            <Col span={12}>
              Cargo
              <Input readOnly value={itemTable.cargo}/>
            </Col>
          </Row>
          }
          {itemTable?.json?.infoPep &&
            <Row gutter={[50]}>
              <Col span={12}>
                Es PEP?
                <Input readOnly value={itemTable.json.infoPep.isPep === 'Ser' ? 'SI' : 'NO'}/>
              </Col>
              <Col span={12}>
                Nacionalidad
                <Input readOnly value={itemTable.json.infoPep.nationality}/>
              </Col>
            </Row>
          }
          <Row gutter={[50]}>
            <Col span={6} >
              Estado
              <Input readOnly value={t("messages.aml.formKycStatus."+item.statusDecl)}/>
            </Col>
            <Col span={6}>
              Fecha de solicitud
              <Input readOnly value={moment(item.fecSolicitud).format('DD/MM/YYYY')}/>
            </Col>
            <Col span={6}>
              Nro. de recordatorios
              <Input readOnly value={item.nroReminders && item.nroReminders > 0 ? item.nroReminders : "Sin Recordatorios"}/>
            </Col>
            <Col span={6}>
              Fecha último recordatorio
              <Input readOnly value={item.lastReminder ? moment(item.lastReminder).format('DD/MM/YYYY') : '-'} />
            </Col>
          </Row>
          {item.statusDecl !== 'PENDIENTE' &&
            <Row gutter={[50]}>
              <Col span={6}>
                Fecha de recepción
                <Input readOnly value={moment(item.receiveDate).format('DD/MM/YYYY')}/>
              </Col>
              {item.statusDecl === 'AUTORIZADA' &&
                <Col span={6}>
                  Fecha de autorización
                  <Input readOnly value={moment(itemTable.updateDate).format("DD/MM/YYYY")}/>
                </Col>
              }
              {item.statusDecl === 'RECHAZADA' &&
                <Col span={6}>
                  Fecha de rechazo
                  <Input readOnly value={moment(itemTable.updateDate).format("DD/MM/YYYY")}/>
                </Col>
              }
              {itemTable?.affirmative !== undefined &&
                <Col span={6}>
                  Respuestas Afirmativas
                  <Input readOnly value={itemTable?.affirmative}/>
                </Col>
              }
              {itemTable.hasPositives !== undefined &&
                <Col span={6}>
                  Vínculos
                  <Input readOnly value={itemTable?.hasPositives ? "Si" : "No"}/>
                </Col>
              }
            </Row>
          }

          {/* FIN INFORMACION SOLICITUD REALIZADA */}
          <Row onClick={handleMore2}>
            <Col span={6}>
              <h3>Comentarios</h3>
            </Col>
            <Col span={4} push={16}>
              <Button type="link" onClick={handleMore2}>Ver más <Icon type={seeMore2 ? "minus" : "plus"}/></Button>
            </Col>
          </Row>
          <hr/>
          {seeMore2 &&
            <div className="div-more-info">
              <Row>
                <Col span={5} >
                  Título
                  <Input onChange={(e) => {handleTitle(e.target.value)}} value={title}/>
                </Col>
                <Col span={18} push={1}>
                  Comentario
                  <Input.TextArea onChange={(e) => {handleComments(e.target.value)}} value={comments} placeholder='Máximo de 1000 caracteres.'/>
                </Col>
              </Row>
              <Col span={6}>
                {lastCommentDate &&
                  <Button onClick={() => setIsHistoryVisible(true)} style={{padding:'unset'}}type="link">Ver comentarios anteriores</Button>
                }
              </Col>
              <Col span={6}>
                <div>
                  Último comentario: {lastCommentDate ? lastCommentDate : "Sin comentarios"}
                </div>
              </Col>
              <Col span={12}>
                <Button onClick={() => handleSaveAttachment()} style={{float:'right', padding:'unset'}}type="link">Guardar</Button>
              </Col>
              <Row>
              </Row>
            </div>
          }
   
          {isHistoryVisible &&
              <HistoryModal formId={item.id} flowType="attachment" schema={commentSchema} data={itemTable.histComments} closeHandler={handleCloseModals}/>
          }
          {/* FIN COMENTARIOS */}
        { item.statusDecl !== 'PENDIENTE' &&
          <>
            <Row onClick={handleMore4}>
              <Col span={10}>
                <h3>Modifique el estado</h3>
              </Col>
              <Col span={4} push={12}>
                <Button type="link" onClick={handleMore4}>Ver más <Icon type={seeMore4 ? "minus" : "plus"}/></Button>
              </Col>
            </Row>
            <hr/>
            {seeMore4 &&
              <div className="status-box div-more-info">
                <Row>
                  <Col span={6} >
                    <h3>Cambio de estado</h3>
                  </Col>
                  <Col span={8} push={10}>
                    <Select
                      style={{width: '100%'}}
                      defaultValue={t("messages.aml.formKycStatus."+item.statusDecl)}
                      onChange={handleOnChangeStatus}
                      // disabled={item.statusDecl === "EVALUACION" ? false : true} (se activa disabled si se cambia el estado)
                      >
                        {varStateOptions(item.statusDecl).map(option =>
                            <Select.Option value={option.val}>{option.label}</Select.Option>
                          )
                        }
                    </Select>
                  </Col>
                </Row>
                  {selectedStatus &&
                  <Modal
                    title="Cambio de estado"
                    centered
                    width={700}
                    visible="true"
                    onCancel={() => setSelectedStatus(null)}
                    footer={[
                      <Button key="save" onClick={() => handleSaveStatus()}>Guardar</Button>
                    ]}
                    >
                      <Row>
                        <Col span={24}>
                          Ha solicitado cambiar el estado de la declaración a {t("messages.aml.formKycStatus."+selectedStatus)}. <br/>
                          Agregue comentarios para dejar registro de este evento los cuales se
                          guardarán automáticamente en el historial de
                          comentarios asociado a esta declaración.
                        </Col>
                      </Row>
                      <Row gutter={[0, 32]}>
                        <Col span={24}>
                          <Input.TextArea placeholder="Agregue comentarios. Máximo 1000 caractéres" onChange={(e) => handleCommentsStateChange(e.target.value)} />
                        </Col>
                      </Row>
                      {selectedStatus === 'RECHAZADA' &&
                      <>
                        <Row gutter={[0, 32]}>
                          <Col span={20}>
                            ¿Requiere enviar una nueva solicitud?
                          </Col>
                          <Col span={4}>
                            <Radio.Group defaultValue={false} buttonStyle="solid" onChange={(e) => setResendOnBoardingForm(e.target.value)}>
                              <Radio.Button value={true}>Si</Radio.Button>
                              <Radio.Button value={false}>No</Radio.Button>
                            </Radio.Group>
                          </Col>
                        </Row>
                      </>
                      }
                  </Modal>
                  }
              </div>
            }
            { itemTable?.relations?.length > 0 &&
              <>
                <Row onClick={() => setSeeMore5(!seeMore5)}>
                  <Col span={6}>
                    <h3>Vínculos</h3>
                  </Col>
                  <Col span={4} push={16}>
                    <Button type="link" onClick={() => setSeeMore5(!seeMore5)}>Ver más <Icon type={seeMore5 ? "minus" : "plus"}/></Button>
                  </Col>
                </Row>
                <hr/>
                {seeMore5 &&
                  <div className="div-more-info">
                      <Row>
                        <Col span={24} >
                          <Table pagination={false} 
                          dataSource={itemTable?.groupRelations} 
                          columns={tableColumns} 
                          size='small'
                          expandedRowRender={rowRecord => <div className='expandable-relations-wrapper'> <Table size='small' dataSource={rowRecord.relations} columns={relationColumn(rowRecord)} bordered pagination={rowRecord.relations?.length > 10 } /> </div>
                          }
                          />
                        </Col>
                      </Row>
                  </div>
                }
              </>
            }
            {(item.type === 'GIFT' || item.type === 'TRAVEL' || item.type === 'FP' || item.type === 'SOC') &&
            <>
              <Row onClick={() => setSeeMore6(!seeMore6)}>
                <Col span={6}>
                  <h3>Datos relevantes</h3>
                </Col>
                <Col span={4} push={16}>
                  <Button type="link" onClick={() => setSeeMore6(!seeMore6)}>Ver más <Icon type={seeMore6 ? "minus" : "plus"}/></Button>
                </Col>
              </Row>
              <hr/>
              {seeMore6 &&
                  <div className="div-more-info">
                    <Row gutter={[30]}>
                      {renderTypeDeclarationInputs().map( item => 
                        <Col span={8}>
                          {item.label}
                          <Input value={item.data} readOnly/>
                        </Col>
                      )
                      }
                    </Row>
                  </div>
                }
            </>
            }
          </>
        }
      </div>
    );
}
export default ModalIndexCard
