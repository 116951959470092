import { uploadBatchService } from '../services'

export default (formData) => {
  return new Promise((resolve, reject) => {
    uploadBatchService.create(formData)
      .then(response => {
        if (response.status === 200) {
          resolve({ success: true })
        }
      })

        .catch(error => {
            if (error.response) {
                    resolve({ error: error.response.data })
            } else {
                console.log('Error al conectar con el servidor', error);
            }
        });
  })
}
