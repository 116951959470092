import { useEffect, useState, useCallback } from "react";
import { Col, Form, Input, DatePicker, Select, notification, Checkbox, InputNumber, Descriptions, Modal } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { saveFormPromise, sendFormPromise } from "../../promises";
import { validateRutHelper, validateCompanyRutHelper } from "../../../../helpers";

const useFormsHooks = ({format, form, match, apiForm, setApiForm, handlerOnChangeObjAttr, initVariablesFromObject}) => {
    const { t } = useTranslation();
    const { getFieldDecorator, validateFields, setFieldsValue, setFields } = form;
    const [objSections, setObjSections] = useState({});

    useEffect(() => {
    }, [])

    const getObjSection = useCallback((objSectionId) => {
        let key = objSectionId
        let obj = objSections[objSectionId]
        if(!obj) {
            const keys = Object.keys(objSections).filter(k => objSectionId.startsWith(k))
            if(keys?.length === 1) {
                key = keys[0]
                obj = objSections[key]
            }
        }
        return [key, obj]
    });

    const registerObjSection = useCallback((objSectionId, objSection) => {
        let obj = { ...objSections, [objSectionId]: objSection };
        setObjSections(obj)
        console.log(objSectionId)
    });

    const registerObjSections = useCallback((obj) => {
      let obj2 = { ...objSections, ...obj };
      setObjSections(obj2)
    });

    const objToVariable = (variable, obj) => {
        let varAttr = { ...variable }
        for(var key in obj) {
          for(var prop in variable) {
            if(variable[prop].key === key && varAttr[prop]) {
              varAttr[prop].val = obj[key]
            }else if(typeof obj[key] === "object" && variable[prop].section === key) {
              for(var attr in obj[key]) {
                if(variable[prop].key === attr && varAttr[prop]) {
                  varAttr[prop].val = obj[key][attr]
                }
              }
            }
          }
        }
        return varAttr
    }

    const validateLengthFieldWithInnerLength = (section)=>{
        return {
          max: section.maxLength,
          message: "Debe tener un máximo de "+ section.maxLength  + " caracteres"
        }
    }

    const setValueFinalObj = (obj, id, attr, value) => {
        const key = Object.values(obj).filter(k => k.id === id)[0]
        key[attr] = value
        return obj
    }

    const handleOnChangeFields = (obj) => {
        const keys = Object.keys(obj)
        const [key, objSection] = getObjSection(keys[0])
        //const [stateObject, stateObjectSetter] = customState(keys[0])
        
        let finalObj = { ...objSection }
        keys.map(key => {
          finalObj = setValueFinalObj(finalObj, key, 'val', obj[key] )
        })
        registerObjSection(key, finalObj)
    };

    const handleOnChangeField = (sectionId, value) => {
        const [key, obj] = getObjSection(sectionId)
        const finalObj = setValueFinalObj(obj, sectionId, 'val', value )
        registerObjSection(key, finalObj)
    };

    const handleReadOnly = (field,readOnly,sectionId=null)=>{
        if (sectionId===null){
          return null
        }else{
          const [key, obj] = getObjSection(sectionId)
          console.log(obj)
          const finalObj = setValueFinalObj(obj, sectionId, 'readOnly', readOnly )
          registerObjSection(key, finalObj)
        }
    }

    const renderFormItem = (formItem) => {
        return (
            <Col
              className={formItem.colClassName}
              span={formItem.wrapperCols}
              offset={formItem.offset}
            >
              <Form.Item
                className={formItem.itemClassName}
                label={formItem.label}
                labelCol={formItem.labelCol > 0 ? { span: formItem.labelCol } : {}}
                wrapperCol={
                  formItem.labelCol > 0 ? { span: formItem.wrapperCol } : {}
                }
              >
                {getFieldDecorator(formItem.name, {
                  rules: formItem.rules,
                  initialValue: formItem.initialValue,
                  validateTrigger: "onChange",
                })(formItem.item)}
              </Form.Item>
            </Col>
        );
    };

    const renderFormItemTable = ({ section, cols=format === 'html' ? 6 : 8, offset, labelCol=0, wrapperCol=0, options=[], validator=null, customRule=null, handlerOnChangeObj=handleOnChangeField, customTitle, colsOption }) => {
        if (section){
          const type = section.type ? section.type : 'input'
          const required = "required" in section ? section.required : true
          const disabled = "disabled" in section ? section.disabled : false
          const initialValue = section.val && section.val !== '' && format === 'html' && type === 'date' ? moment(section.val, "DD/MM/YYYY") : section.val
          return renderFormItem({
            label: customTitle ? customTitle : section.title,
            name: section.id,
            initialValue: initialValue,
            colClassName: "topLabel",
            labelCol: labelCol,
            wrapperCol: wrapperCol,
            rules:
            [
              { required: required, message: t( "messages.aml.requestedField")},
              ...validator ? [{ validator: validator }]:[],
              ...type==='input' ? [validateLengthFieldWithInnerLength(section)]:[],
              ...customRule ? [customRule]:[]
            ],
            wrapperCols: cols,
            offset: offset,
            item: (
              format === 'html' || type === 'check' ?
                type === 'input' ?
                  <Input
                    placeholder={section.placeholder ?? "Ingrese texto"}
                    autoComplete="off"
                    onFocus= {(e)=>handleReadOnly(e.target.id,false,section.id)}
                    onBlur= {(e)=>handleReadOnly(e.target.id,true,section.id)}
                    readOnly = {section.readOnly}
                    onChange={(e) => handlerOnChangeObj( section.id, e.target.value ) }
                    disabled={disabled}
                  />
                : type === 'number' ?
                  <InputNumber
                    style={{width:'100%'}}
                    placeholder={section.placeholder ?? 'Ingrese número'}
                    autoComplete="off"
                    onFocus= {(e)=>handleReadOnly(e.target.id,false,section.id)}
                    onBlur= {(e)=>handleReadOnly(e.target.id,true,section.id)}
                    min= {section.min}
                    max={section.max}
                    formatter = { section.isCurrency ? (value => `$ ${value}`)  : section.isPercentage ? (value => `${value}%`) : null}
                    readOnly = {section.readOnly}
                    onChange={(value) => {
                    handlerOnChangeObj( section.id, value )
                    }}
                    disabled={disabled}
                  />
                : type === 'textarea' ?
                  <Input.TextArea
                    placeholder={section.placeholder ?? "Ingrese texto"}
                    autoComplete="off"
                    onFocus= {(e)=>handleReadOnly(e.target.id,false,section.id)}
                    onBlur= {(e)=>handleReadOnly(e.target.id,true,section.id)}
                    readOnly = {section.readOnly}
                    onChange={(e) => handlerOnChangeObj( section.id, e.target.value ) }
                    disabled={disabled}
                    style = {{width: '100%'}}
                  />
                : type === 'select' ?
                  <Select
                    allowClear
                    showSearch
                    size={section.size}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={section.multiple ? "Seleccione una o más opciones" : "Seleccione una opción"}
                    onChange={(value, codigo) => handlerOnChangeObj(section.id, value, null, codigo)}
                    disabled={disabled}
                    style={{ width: '100%' }}
                    mode = {section.multiple ? 'multiple' : ''}
                  >
                    { options.map((option) => (<Select.Option value={option.val} key={option.key} code={option.code}>{ option.text }</Select.Option>)) }
                  </Select>
                : type === 'date' ?
                  <DatePicker
                    onFocus= {(e)=>handleReadOnly(e.currentTarget.id,false,section.id)}
                    onBlur= {(e)=>handleReadOnly(e.currentTarget.id,true,section.id)}
                    readOnly = {section.readOnly}
                    format={"DD/MM/YYYY"}
                    placeholder={"Ingrese la fecha"}
                    style = {{width: '100%'}}
                    disabled={disabled}
                    mode={type}
                    onChange={(momentObj, str) => {
                      handlerOnChangeObj( section.id, momentObj ? moment(momentObj).format( "DD/MM/YYYY" ) : null )
                    }}
                  />
                : type === 'check' ?
                  <Checkbox.Group
                    style={{width: '100%'}}
                    className={section.title && section.title !== '' ? 'has-title' : ''}
                    onChange={(value) => handlerOnChangeObj(section.id, value) }
                    >
                      <hr/>
                    {options.map((option) => (
                      <Col span={colsOption ? colsOption : section.colsOption}>
                        <Checkbox value={option.val}>
                          { option.text }
                        </Checkbox>
                      </Col>
                    ))}
                  </Checkbox.Group>
                :<Input type="small"/>
              :
                <Input size="small"/>
            ),
          })
        }else{
          return <Col span={6}><Input disabled placeholder="SECCION NO ENVIADA"></Input></Col>
        }
    }
    
    const renderFormItemObj = (params) => {
        return renderFormItemTable({...params, handlerOnChangeObj: handlerOnChangeObjAttr})
    }

    const handleOnClear = (section) => {
      const clearFieldsById = (key, stateObj) => {
        setFieldsValue(Object.values(stateObj).reduce((acu, val) => {
          return { ...acu, [val.id]: undefined };  // Asignar undefined a los campos con ese id
        }, {}));
        let obj = {...stateObj}
        Object.values(obj).map(o => {
          o.val = undefined
        })
        registerObjSection(key, obj)
      }
      const [key, obj] = getObjSection(section)
      clearFieldsById(key, obj)
    };

    const handleDeleteAttrTable = ({attrTable, attrRecords='records', index}) => {
      return () => {
        const [keyTable, objTable] = getObjSection(attrTable)
        let obj = { ...objTable }
        const valueRecords = Object.values(obj).filter(k => k.key === attrRecords)[0]
  
        let xx = valueRecords.val;
        xx.splice(index, 1);
        registerObjSection(keyTable, obj)
  
        let formToUpdate = { ...apiForm };
        saveFormPromiseLocal(formToUpdate);
      };
    };

    const handleOnAddAttrTable = (attrObj, attrTable, attrRecords='records') => {
      const [key, obj] = getObjSection(attrObj)

      validateFields(Object.values(obj).map(v => v.id)).then((error, values) => {
        const tableOk = Object.keys(obj).reduce(
          (acc, e) => {
            return {
              ...acc,
              [obj[e].key]: obj[e].val,
            };
          },
          {}
        );
        const [key1, obj1] = getObjSection(attrTable)
        let _obj = { ...obj1 };
        const valueRecords = Object.values(_obj).filter(k => k.key === attrRecords)[0]
        valueRecords.val.push(tableOk);
        registerObjSection(key1, _obj)

        let formToUpdate = { ...apiForm };
        saveFormPromiseLocal(formToUpdate).then(ret => {
          if (ret.success) {
            handleOnClear(attrObj);
          }
        });
      }).catch(error => notification.error({ message: "Faltan campos requeridos" }));
    };

    const entriesToObj = (obj) => {
      const objAttr = {}
      Object.entries(obj).map(([key, value]) => {
        if(value.section) {
          if(!objAttr[value.section]) objAttr[value.section] = {}
          objAttr[value.section][value.key] = value.val
        }else {
          objAttr[value.key] = value.val
        }
      })
      return objAttr
    }

    const saveFormPromiseLocal = async (form) => {
      let response = await saveFormPromise(form);
      if(response.code !== 'OK' && response.form !== undefined && response.form !== null) {
        setApiForm(response.form)
        initVariablesFromObject(response.form)
  
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
        });
  
        return { success: false, form: response.form}
      }else {
        setApiForm(form)
        return { success: true }
      }
    }

    const rutValidator = (rule, value, cb) => {
      if (value && !validateRutHelper(value)) {
        cb("Documento no válido");
      }
      cb();
    };
  
    const rutValidatorCompany = (rule, value, cb) => {
      if (value && !validateCompanyRutHelper(value)) {
        cb("Documento de empresa no válido");
      }
      cb();
    };
  
    const rutValidatorCompanyPerson = (rule, value, cb) => {
      if (value && !validateCompanyRutHelper(value)&&!validateRutHelper(value)) {
        cb("Documento no válido");
      }
      cb();
    };
  
    const docValidator = (tipoDoc, type) => {
      if (tipoDoc === "Chile-Rut")
        if (type === 'persona') return rutValidator
        else if (type === 'empresa') return rutValidatorCompany
        else return rutValidatorCompanyPerson
      else return null;
    };

    const toDescriptionsPdf = (collection, stateObject, typeEntity) => (
      <>
        {collection.map((collectionObject, index) => (
          <>
            <div className="descriptions-pdf">
              <h4 className="descriptions-numeral">#{index + 1}</h4>
              <Descriptions title="" column={1} bordered size="small">
                {Object.keys(stateObject).map((key) => {
                  const value = collectionObject[stateObject[key].key];
                  
                  // Verifica si el valor existe y no está vacío
                  if (
                    (!typeEntity || !stateObject[key].typeEntity || stateObject[key].typeEntity.includes(typeEntity)) &&
                    value !== undefined && value !== null && value !== ''
                  ) {
                    return (
                      <Descriptions.Item label={stateObject[key].title}>
                        {value}
                      </Descriptions.Item>
                    );
                  } else {
                    return null;
                  }
                })}
              </Descriptions>
            </div>
            <br />
          </>
        ))}
      </>
    );

    const sendDocument = (requestId = "", formObj = apiForm) => {
      Modal.confirm({
        title: '¿Desea enviar el formulario?',
        content: 'Para confirmar el envío del formulario debe presionar "Enviar"',
        okText: 'Enviar',
        cancelText: 'Cancelar',
        onOk() {
          sendFormPromise(match.params.id, requestId).then((response) => {
            if (response.code === "OK") {
              let formSend = { ...formObj, status: "SENT" };
              setApiForm(formSend);
            } else {
              notification["error"]({
                message: t("messages.aml.notifications.anErrorOcurred"),
                description: 'Comuníquese con soporte.aml@gesintel.cl'
              });
              setApiForm(response.form);
            }
          });
        },
        onCancel() {},
      }); 
    }

    return { getObjSection, registerObjSection, registerObjSections, handleOnChangeFields, handleOnChangeField, renderFormItem, renderFormItemTable, renderFormItemObj, objToVariable, handleOnClear, handleDeleteAttrTable, handleOnAddAttrTable, entriesToObj, saveFormPromiseLocal, sendDocument, docValidator, toDescriptionsPdf };

}
export default useFormsHooks;

