import "./FormProv.scss";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row, Spin, notification, Table, Icon, Radio, Divider } from "antd";
import { FormLayout } from "../../../../../layouts";
import { withRouter } from "react-router-dom";
import { getFormPromise, getParamsPromise } from "../../../promises";
import moment from "moment";
import Logo from '../../../../../layouts/commonComponent/components/Logo/Logo'
import { getCountriesCodePromise } from "../../../../OnBoarding/promises";
import apiConfig from '../../../../../config/api'
import { cargosOptions, relationshipOptions, tipoDocOptions, tipoFundacionOptions } from "../../assets/optList";
import useFormsHooks from "../../hooks/useFormsHooks";

const FormProv = ({ form, match }) => {
  const { t } = useTranslation();
  const { getFieldsError, validateFieldsAndScroll, setFields } = form;
  const [isValidate, setIsValidate] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  const [paises, setPaises] = useState(null);
  const [date, setDate] = useState(0);
  const [signed, setSigned] = useState(false);
  const [user, setUser] = useState({});
  const [apiForm, _setApiForm] = useState(null);
  const [colLogo, setColLogo] = useState(4);
  const [format, setFormat] = useState("html");
  const [openSigner, setOpenSigner] = useState(false);
  const hasSign = false
  const [clientName, setClientName] = useState("");
  const [signData, setSignData] = useState(null);
  const apiFormRef = useRef(apiForm);
  const [validarRegistros, setValidarRegistros] = useState(false);
  const [params, setParams] = useState([]);
  const [empresasGrupo, setEmpresasGrupo] = useState([]);
  const [registerData, setRegisterData] = useState();

  const setApiForm = data => {
    apiFormRef.current = data;
    _setApiForm(data);
  };

  const handlerOnChangeObjAttr = async (sectionId, value, formObj) => {
    handleOnChangeField(sectionId, value)
    const binfo = entriesToObj(basicInformation)
    const iMall = entriesToObj(mallaParental)
    const iPsoc = entriesToObj(consultoria)
    const iParSoc = entriesToObj(participacionSocial);
    const iEmp = entriesToObj(interesEmpresa);
    const pRel = entriesToObj(partesRelacionadas);    
    const exTrab = entriesToObj(exTrabajador);
    const iRelComp = entriesToObj(relacionCompetencia);
    const iRelPar = entriesToObj(relacionParentesco);
    const iFuncPub = entriesToObj(funcionariosPublicos);
    const resPen = entriesToObj(responsabilidadPenal);
    const iOtrosCon = entriesToObj(otrosConflictos);

    if(!formObj) formObj = apiForm
    const json = { 
      basicInformation: binfo,
      mallaParental: iMall, 
      consultoria: iPsoc, 
      participacionSocial: iParSoc,
      interesEmpresa: iEmp,
      partesRelacionadas: pRel,
      exTrabajador: exTrab,
      relacionCompetencia: iRelComp,
      relacionParentesco: iRelPar,
      funcionariosPublicos: iFuncPub,
      responsabilidadPenal: resPen,
      otrosConflictos: iOtrosCon
    }
    let formToUpdate = { ...formObj, json };
    let ret = await saveFormPromiseLocal(formToUpdate);
    if(!ret.success) {
    //  setFieldsValue({[field]: ret.form[field]})
    }
  }

  const numTelValidator = (rule, value, callback) => {
    const regex = /^[0-9]*$/;
    if (value && value.length !== 9) {
      callback("Número debe tener 9 dígitos");
    } else if (value && !(regex.test(value))) {
      callback("Debe contenter sólo números");
    } else {
      callback();
    }
  };

  const emailValidator = (rule, value, callback) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (value && regex.test(value) === false){
      callback("Debe ingresar un correo electrónico válido");
    }else{
      callback();
    }
  }

  const initVariablesFromObject = (obj, objs={}) => {
    let objs1 = {...objs}
    if (obj.json) {
      let objs1 = {...objs}
      if (obj.json.basicInformation) {
        let binfo = objToVariable(basicInformationIS, obj.json.basicInformation);
        objs1['basicInformation'] = binfo
      }
      if (obj.json.mallaParental) {
        let iMall = objToVariable(mallaParentalIS, obj.json.mallaParental);
        objs1['mallaParental'] = iMall
      }
      if (obj.json.consultoria) {
        let consult = objToVariable(consultoriaIS, obj.json.consultoria);
        objs1['consultoria'] = consult
      }
      if (obj.json.participacionSocial) {
        let partEmp = objToVariable(participacionSocialIS, obj.json.participacionSocial);
        objs1['participacionSocial']= partEmp
      }
      if (obj.json.interesEmpresa) {
        let intEmp = objToVariable(interesEmpresaIS, obj.json.interesEmpresa);
        objs1['interesEmpresa'] = intEmp
      }
      if (obj.json.partesRelacionadas) {
        let fuerHor = objToVariable(partesRelacionadasIS, obj.json.partesRelacionadas);
        objs1['partesRelacionadas'] = fuerHor
      }
      if (obj.json.exTrabajador) {
        let empleosAnt = objToVariable(exTrabajadorIS, obj.json.exTrabajador);
        objs1['exTrabajador'] = empleosAnt
      }
      if (obj.json.relacionCompetencia) {
        let relComp = objToVariable(relacionCompetenciaIS, obj.json.relacionCompetencia);
        objs1['relacionCompetencia'] = relComp
      }
      if (obj.json.relacionParentesco) {
        let relPar = objToVariable(relacionParentescoIS, obj.json.relacionParentesco);
        objs1['relacionParentesco'] = relPar
      }
      if (obj.json.funcionariosPublicos) {
        let funcPub = objToVariable(funcionariosPublicosIS, obj.json.funcionariosPublicos);
        objs1['funcionariosPublicos'] = funcPub
      }
      if (obj.json.responsabilidadPenal) {
        let relFund = objToVariable(responsabilidadPenalIS, obj.json.responsabilidadPenal);
        objs1['responsabilidadPenal'] = relFund
      }
      if (obj.json.otrosConflictos) {
        let otrosCon = objToVariable(otrosConflictosIS, obj.json.otrosConflictos);
        objs1['otrosConflictos'] = otrosCon
      }
    }
    registerObjSections(objs1)
  };

  const { getObjSection, registerObjSections, handleOnChangeFields, handleOnChangeField, renderFormItem, renderFormItemTable, renderFormItemObj, objToVariable, handleOnClear, handleDeleteAttrTable, handleOnAddAttrTable, entriesToObj, saveFormPromiseLocal, sendDocument, docValidator, toDescriptionsPdf } = useFormsHooks({format, form, match, apiForm, setApiForm, handlerOnChangeObjAttr, initVariablesFromObject});

  let registersStop = {};

  const radioStyle = {
    display: 'block',
    height: '22px',
    lineHeight: '22px',
    pointerEvents: format === 'pdf' ? 'none' : 'auto'
  };

  // INTRODUCCION
  const basicInformationIS= {
    nombre: { key: "nombre", title:"Nombre", maxLength:100, id: "basicInformationNombre", required: true, type: 'input'},
    tipoDoc: { key: "tipoDoc", title:"Tipo de documento de identidad", id: "basicInformationTipoDoc", required: true, type: 'select'},
    doc: { key: "doc", title:"Documento de identidad", maxLength:50, id: "basicInformationDoc", required: true, type: 'input'},
    cargo: { key: "cargo", title:"Cargo", maxLength:100, id: "basicInformationCargo", required: true, type: 'input'},
    telefono: { key: "telefono", title:"Teléfono", id: "basicInformationTelefono", required: true, type: 'input'},
    correo: { key: "correo", title:"Correo electrónico", maxLength:100, id: "basicInformationCorreo", required: true, type: 'input'},
    esRepresentante: { key: "esRepresentante", title:"¿Es representante legal?", id: "basicInformationEsRepresentante", required: true, type: 'select'},
  };
  const [keyBasicInformation, basicInformation] = getObjSection('basicInformation')

  // MALLA PARENTAL
  const mallaParentalIS= {
    // mallaParentalHasMalla: { key: "hasMalla", val: true, title: "", maxLength:100, id: "mallaParentalHasMalla", required: true},
    records: { key: "records", val: [], id: "mallaParentalRecords"},
  };
  const [keyMallaParental, mallaParental] = getObjSection('mallaParental')


  const mallaParObjIS = {
    parentesco: { key: "parentesco", title: "Parentesco", maxLength:100, id: "mallaParObjParentesco", required: true, type: 'select'},
    nombre: { key: "nombre", title: "Nombre", maxLength:100, id: "mallaParObjNombre", required: true, type: 'input'},
    tipoDoc: { key: "tipoDoc", title: "Tipo de documento de identidad", id: "mallaParObjTipoDoc", required: true, type: 'select'},
    doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "mallaParObjDoc", required: true, type: 'input'},
  };
  const [keyMallaParObj, mallaParObj] = getObjSection('mallaParObj')

  // PARTICIPACION EN SOCIEDADES
  const consultoriaIS= {
    hasRecords: { key: "hasRecords", val: undefined, title: "", id: "consultoriaHasConsultoria", required: true},
    records: { key: "records", val: [], id: "consultoriaRecords"},
  };
  const [keyConsultoria, consultoria] = getObjSection('consultoria')

  const dataConsultoriaIS = {
    nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataConsultoriaNombre", required: true, type: 'input'},
    tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "dataConsultoriaTipoDoc", required: true, type: 'select'},
    doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "dataConsultoriaDoc", required: true, type: 'input'},
    cargo: { key: "cargo", title: "Cargo", id: "dataConsultoriaCargo", required: true, type: 'select'},
    razonSocial: { key: "razonSocial",  title: "Razón Social", maxLength:100, id: "dataConsultoriaRazonSocial", required: true, type: 'input'},
    tipoDocEmp: { key: "tipoDocEmp", title: "Tipo de documento de identidad", maxLength:100, id: "dataConsultoriaTipoDocEmp", required: true, type: 'select'},
    docEmp: { key: "docEmp",  title: "Documento de identidad", maxLength:50, id: "dataConsultoriaDocEmp", required: true, type: 'input'},
    tipoServicios: { key: "tipoServicios", title: "Indique el tipo de servicio ofrecido", maxLength:100, id: "dataConsultoriaTipoDeServicios", required: true, type: 'input'},
    grupo: { key: "grupo",  title: "Empresa del Grupo", maxLength:100, id: "dataConsultoriaGrupo", required: true, type: 'select'},
  };
  const [keyDataConsultoria, dataConsultoria] = getObjSection('dataConsultoria')

  // PARTICIPACION EN PROPIEDAD DE LA EMPRESA
  const participacionSocialIS= {
    hasRecords: { key: "hasRecords", val: undefined, title: "", id: "participacionSocialHasPemp", required: true},
    records: { key: "records", val: [], id: "participacionSocialRecords"},
  };
  const [keyParticipacionSocial, participacionSocial] = getObjSection('participacionSocial')

  const partSocialObjIS = {
    nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "partSocialObjNombre", required: true, type: 'input'},
    cargo: { key: "cargo", title: "Cargo", id: "partSocialObjCargo", required: true, type: 'select'},
    tipoDoc: { key: "tipoDoc", title: "Tipo de documento de identidad", id: "partSocialObjTipoDoc", required: true, type: 'select'},
    doc: { key: "doc", title: "Documento de identidad", maxLength:50, id: "partSocialObjDoc", required: true, type: 'input'},
    grupo: { key: "grupo", title: "Empresa del Grupo", id: "partSocialObjGrupo", required: true, type: 'select'},
    cargoEnEmpresaExterna: { key: "cargoEnEmpresaExterna", title: "Cargo", id: "partSocialObjCargoEnEmpresaExterna", required: true, type: 'select'},
    tipoPropiedad: { key: "tipoPropiedad", title: "Tipo de Propiedad", id: "partSocialObjTipoPropiedad", required: true, type: 'select'},
    porcentajeParticipacion: { key: "porcentajeParticipacion",  title: "Porcentaje de Participación", id: "partSocialObjPorcentajeParticipacion", required: true, type: 'number', isPercentage: true},
    nombreEmpIndirecta: { key: "nombreEmpIndirecta", title: "Nombre Empresa de propiedad indirecta", maxLength:100, id: "partSocialObjNombreEmpIndirecta", required: true, type: 'input'},
    tipoDocEmpIndirecta: { key: "tipoDocEmpIndirecta", title: "Tipo de documento Empresa propiedad indirecta", id: "partSocialObjTipoDocEmpIndirecta", required: true, type: 'select'},
    docEmpIndirecta: { key: "docEmpIndirecta", title: "Documento Empresa propiedad indirecta", maxLength:50, id: "partSocialObjDocEmpIndirecta", required: true, type: 'input'},
  };
  const [keyPartSocialObj, partSocialObj] = getObjSection('partSocialObj')


  // RELACIÓN CON PROVEEDORES, CONTRATISTAS, Y/O CLIENTES
const interesEmpresaIS = {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "interesEmpresaHasInteresEmpresa", required: true },
  records: { key: "records", val: [], id: "interesEmpresaRecords" },
};
const [keyInteresEmpresa, interesEmpresa] = getObjSection('interesEmpresa')

const intEmpObjIS = {
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "intEmpObjNombre", required: true, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "intEmpObjTipoDoc", required: true, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "intEmpObjDoc", required: true, type: 'input'},
  descripcion: { key: "descripcion",  title: "Descripción de la situación", maxLength:500, id: "intEmpObjDescripcion", required: true, type: 'textarea'},
};
const [keyIntEmpObj, intEmpObj] = getObjSection('intEmpObj')

// ACTIVIDADES FUERA DE LA EMPRESA
const partesRelacionadasIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "partesRelacionadasHasActFueraHorario", required: true},
  records: { key: "records", val: [], id: "partesRelacionadasRecords"},
};
const [keyPartesRelacionadas, partesRelacionadas] = getObjSection('partesRelacionadas')

const partRelObjIS = {
  descripcion: { key: "descripcion",  title: "Descripción de la situación", maxLength:500, id: "partRelObjDescripcion", required: true, type: 'textarea'},
  grupo: { key: "grupo",  title: "Empresa del Grupo", id: "partRelObjGrupo", required: true, type: 'select'},
};
const [keyPartRelObj, partRelObj] = getObjSection('partRelObj')

// RELACIONES LABORALES ANTERIORES
const exTrabajadorIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "exTrabajadorHasexTrabajador", required: true},
  records: { key: "records", val: [], id: "exTrabajadorRecords"},
};
const [keyExTrabajador, exTrabajador] = getObjSection('exTrabajador')

const exTrabObjIS = {
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "exTrabObjNombre", required: true, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "exTrabObjTipoDoc", required: true, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "exTrabObjDoc", required: true, type: 'input'},
  cargo: { key: "cargo",  title: "Cargo", id: "exTrabObjCargo", required: true, type: 'select'},
  fechaSalida: { key: "fechaSalida",  title: "Fecha de Salida", id: "exTrabObjFechaSalida", required: true, type: 'date'},
  grupo: { key: "grupo",  title: "Empresa del Grupo", id: "exTrabObjGrupo", required: true, type: 'select'},
};
const [keyExTrabObj, exTrabObj] = getObjSection('exTrabObj')

// RELACION CON LA COMPETENCIA
const relacionCompetenciaIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "relacionCompetenciaHasCompetencia", required: true},
  records: { key: "records", val: [], id: "relacionCompetenciaRecords"},
};
const [keyRelacionCompetencia, relacionCompetencia] = getObjSection('relacionCompetencia')

const dataCompObjIS = {
  razonSocial: { key: "razonSocial",  title: "Razón Social", maxLength:100, id: "dataCompObjRazonSocial", required: true, type: 'input'},
  tipoDocEmp: { key: "tipoDocEmp",  title: "Tipo de documento de identidad", id: "dataCompObjTipoDocEmp", required: true, type: 'select'},
  docEmp: { key: "docEmp",  title: "Documento de identidad", maxLength:50, id: "dataCompObjDocEmp", required: true, type: 'input'},
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataCompObjNombre", required: true, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "dataCompObjTipoDoc", required: true, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "dataCompObjDoc", required: true, type: 'input'},
  cargo: { key: "cargo",  title: "Cargo", id: "dataCompObjCargo", required: true, type: 'select'},
};
const [keyDataCompObj, dataCompObj] = getObjSection('dataCompObj')

//PARENTESCO
const relacionParentescoIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "relacionParentescoHasParentesco", required: true},
  records: { key: "records", val: [], id: "relacionParentescoRecords"},
};
const [keyRelacionParentesco, relacionParentesco] = getObjSection('relacionParentesco')

const dataRelParObjIS = {
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataRelParObjNombre", required: true, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "dataRelParObjTipoDoc", required: true, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "dataRelParObjDoc", required: true, type: 'input'},
  cargo: { key: "cargo",  title: "Cargo", id: "dataRelParObjCargo", required: true, type: 'select'},
  parentesco: { key: "parentesco",  title: "Parentesco", id: "dataRelParObjParentesco", required: true, type: 'select'},
  nombreRelacionado: { key: "nombreRelacionado",  title: "Nombre", maxLength:100, id: "dataRelParObjNombreRelacionado", required: true, type: 'input'},
  tipoDocRelacionado: { key: "tipoDocRelacionado",  title: "Tipo de documento de identidad", id: "dataRelParObjTipoDocRelacionado", required: true, type: 'select'},
  docRelacionado: { key: "docRelacionado",  title: "Documento de identidad", maxLength:50, id: "dataRelParObjDocRelacionado", required: true, type: 'input'},
  cargoRelacionado: { key: "cargoRelacionado",  title: "Cargo ocupado en la Empresa", id: "dataRelParObjCargoRelacionado", required: true, type: 'select'},
  grupoRelacionado: { key: "grupoRelacionado",  title: "Empresa del Grupo", id: "dataRelParObjGrupoRelacionado", required: true, type: 'select'},
};
const [keyDataRelParObj, dataRelParObj] = getObjSection('dataRelParObj')

// FUNCIONARIOS PUBLICOS O PERSONAS EXPUESTAS POLÍTICAMENTE
const funcionariosPublicosIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "funcionariosPublicosHasPep", required: true},
  records: { key: "records", val: [], id: "funcionariosPublicosRecords"},
};
const [keyFuncionariosPublicos, funcionariosPublicos] = getObjSection('funcionariosPublicos')

const dataPepObjIS = {
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataPepObjNombre", required: true, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "dataPepObjTipoDoc", required: true, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "dataPepObjDoc", required: true, type: 'input'},
  cargo: { key: "cargo",  title: "Cargo", id: "dataPepObjCargo", required: true, type: 'select'},
  categoria: { key: "categoria",  title: "Categoría", maxLength:100, id: "dataPepObjCategoria", required: true, type: 'select'},
  razonSocial: { key: "razonSocial",  title: "Institución u Organismo Público", maxLength:100, id: "dataPepObjRazonSocial", required: true, type: 'input'},
  cargoEnInstitucion: { key: "cargoEnInstitucion",  title: "Cargo ejercido", maxLength:100, id: "dataPepObjCargoEnInstitucion", required: true, type: 'input'},
  fechaTermino: { key: "fechaTermino",  title: "Fecha de término", id: "dataPepObjFechaTermino", required: false, type: 'date'},
};
const [keyDataPepObj, dataPepObj] = getObjSection('dataPepObj')

// RELACIÓN CON FUNDACIONES, CORPORACIONES, INSTITUCIONES DE BENEFICIENCIA Y/O SIN FINES DE LUCRO
const responsabilidadPenalIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "responsabilidadPenalHasFundaciones", required: true},
  records: { key: "records", val: [], id: "responsabilidadPenalRecords"},
};
const [keyResponsabilidadPenal, responsabilidadPenal] = getObjSection('responsabilidadPenal')

const resPenalObjIS = {
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "resPenalObjNombre", required: true, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", maxLength:100, id: "resPenalObjTipoDoc", required: true, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "resPenalObjDoc", required: true, type: 'input'},
  descripcion: { key: "descripcion",  title: "Descripción de la situación", maxLength:500, id: "resPenalObjArea", required: true, type: 'textarea'},
};
const [keyResPenalObj, resPenalObj] = getObjSection('resPenalObj')

// OTROS CONFLICTOS DE INTERÉS
const otrosConflictosIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", maxLength:100, id: "otrosConflictosHasOtrosConflictos", required: true},
  records: { key: "records", val: [], id: "otrosConflictosRecords"},
};
const [keyOtrosConflictos, otrosConflictos] = getObjSection('otrosConflictos')

const dataOtrosConObjIS = {
  razonSocial: { key: "razonSocial",  title: "Razón Social", maxLength:100, id: "dataOtrosConObjRazonSocial", required: false, type: 'input'},
  tipoDocEmp: { key: "tipoDocEmp",  title: "Tipo de documento de identidad", id: "dataOtrosConObjTipoDocEmp", required: false, type: 'select'},
  docEmp: { key: "docEmp",  title: "Documento de identidad", maxLength:50, id: "dataOtrosConObjDocEmp", required: false, type: 'input'},
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataOtrosConObjNombre", required: false, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "dataOtrosConObjTipoDoc", required: false, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "dataOtrosConObjDoc", required: false, type: 'input'},
  descripcion: { key: "descripcion",  title: "Ingrese una breve descripción de la situación", maxLength:500, id: "dataOtrosConObjDescripcion", required: true, type: 'textarea'},
};
const [keyDataOtrosConObj, dataOtrosConObj] = getObjSection('dataOtrosConObj')

useEffect(() => {
  if(partSocialObj) {
    if(partSocialObj.tipoPropiedad.val !== "Indirecta" || (partSocialObj?.cargoEnEmpresaExterna?.val !== "Accionista" && partSocialObj?.cargoEnEmpresaExterna?.val !== "Propietario")) {
      setFields({ [partSocialObj.nombreEmpIndirecta.id]: { value: undefined },
        [partSocialObj.tipoDocEmpIndirecta.id]: { value: undefined },
        [partSocialObj.docEmpIndirecta.id]: { value: undefined },
      })

      if(handleOnChangeFields) {
        handleOnChangeFields({ [partSocialObj.nombreEmpIndirecta.id]:  undefined, 
          [partSocialObj.tipoDocEmpIndirecta.id]: undefined, 
          [partSocialObj.docEmpIndirecta.id]: undefined, 
        })
      }
      if(partSocialObj?.cargoEnEmpresaExterna?.val !== "Accionista" && partSocialObj?.cargoEnEmpresaExterna?.val !== "Propietario") {
        setFields({ [partSocialObj.tipoPropiedad.id]: { value: undefined },
          [partSocialObj.porcentajeParticipacion.id]: { value: undefined },
        })

        if(handleOnChangeFields) {
          handleOnChangeFields({ [partSocialObj.tipoPropiedad.id]:  undefined, 
            [partSocialObj.porcentajeParticipacion.id]: undefined, 
          })
        }
      }
    }
  }
}, [partSocialObj?.cargoEnEmpresaExterna?.val, partSocialObj?.tipoPropiedad?.val]);

//PARTICIPACION EN LA PROPIEDAD DE LA EMPRESA --> PARENTESCO === MI PERSONA
useEffect(() => {
  if(partSocialObj) {
    let nombre = undefined
    let rut = undefined
    let tipoDoc = undefined
    if (partSocialObj.parentesco.val === "Mi Persona") {
      nombre = registerData.nombre
      rut = registerData.rut
      tipoDoc = registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
    }
    setFields({ [partSocialObj.nombre.id]: { value: nombre }, 
                [partSocialObj.tipoDoc.id]: { value: tipoDoc },
                [partSocialObj.doc.id]: { value: rut }
              })
    if(handleOnChangeFields) {
      handleOnChangeFields({ 
        [partSocialObj.nombre.id]:  nombre, 
        [partSocialObj.tipoDoc.id]: tipoDoc, 
        [partSocialObj.doc.id]: rut, 
      })
    }
  }
}, [partSocialObj?.parentesco?.val]);

//PARTICIPACION EN OTRAS SOCIEDADES --> PARENTESCO === MI PERSONA
useEffect(() => {
  if(dataConsultoria) {
    let nombre = undefined
    let rut = undefined
    let tipoDoc = undefined
    if (dataConsultoria.parentesco.val === "Mi Persona") {
      nombre = registerData.nombre
      rut = registerData.rut
      tipoDoc = registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
    }
    setFields({ [dataConsultoria.nombre.id]: { value: nombre },
                [dataConsultoria.tipoDoc.id]: { value: tipoDoc },
                [dataConsultoria.doc.id]: { value: rut }
              })

    if(handleOnChangeFields) {
      handleOnChangeFields({ [dataConsultoria.nombre.id]:  nombre, 
        [dataConsultoria.tipoDoc.id]: tipoDoc, 
        [dataConsultoria.doc.id]: rut
      })
    }
  }
}, [dataConsultoria?.parentesco?.val]);

  useEffect(() => {
    const obj = { basicInformation: basicInformationIS,
          mallaParental: mallaParentalIS,
          mallaParObj: mallaParObjIS,
          consultoria: consultoriaIS,
          dataConsultoria: dataConsultoriaIS,
          participacionSocial: participacionSocialIS,
          partSocialObj: partSocialObjIS,
          interesEmpresa: interesEmpresaIS,
          intEmpObj: intEmpObjIS,
          partesRelacionadas: partesRelacionadasIS,
          partRelObj: partRelObjIS,
          exTrabajador: exTrabajadorIS,
          exTrabObj: exTrabObjIS,
          relacionCompetencia: relacionCompetenciaIS,
          dataCompObj: dataCompObjIS,
          relacionParentesco: relacionParentescoIS,
          dataRelParObj: dataRelParObjIS,
          funcionariosPublicos: funcionariosPublicosIS,
          dataPepObj: dataPepObjIS,
          responsabilidadPenal: responsabilidadPenalIS,
          resPenalObj: resPenalObjIS,
          otrosConflictos: otrosConflictosIS,
          dataOtrosConObj: dataOtrosConObjIS
    }

    if (match.params.view === "pdf") {
      setColLogo(5);
      setFormat("pdf");
    }

    getCountriesCodePromise().then((response) => {
      setPaises(response)
    })
    getFormPromise(match.params.id).then((response) => {
      if (response?.data?.status) {
        setApiForm(response.data);
        initVariablesFromObject(response.data, obj)
        setUser(response.data.recipient.request.createUser);
        setClientName(response.data.recipient.request.createUser.cliente.name);
        setRegisterData(response.data.recipient.record)
        
        if(response.data.recipient.request.createUser.cliente.outsourcer)
        {
          setEmpresasGrupo(response.data.recipient.request.createUser.cliente.clientes.map(item => {
            return item.name
          }))
        }

        // getParamsPromise(
        //   response.data.recipient.request.createUser.cliente.id
        // ).then((response) => {
        //   setParams(response.data);
        //   setEmpresasGrupo(response.data.empresas)
        // });

        if(response.data.status === "SENT") {
          setDate(moment(response.data.receiveDate).format("DD-MM-YYYY"));
        }else {
          setDate(moment().format("DD-MM-YYYY"));
        }
      }
      setIsloading(false);
    });

    const handleMessage = (message) => {
      if(message.data && typeof message.data === 'string' && message.data.startsWith('DOCUSIGN') && apiConfig.url.startsWith(message.origin)) {
        const data = message.data.split('|')
        if(data.length === 4) {
          if(data[1] === "signing_complete") {
            const reqId = data[2] + "|" + data[3]
            sendDocument(reqId, apiFormRef.current)
          }else {
            closeHandlerSigner();
            notification["warning"]({
              message: "Firma cancelada",
            });
          }
        }
      }
    }

    window.addEventListener("message", handleMessage);
    return function () {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const closeHandlerSigner = () => {
    setSignData(null)
    setOpenSigner(false)
  }
  
  const partSocialColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
    },
    {
      title: "Cargo en la otra empresa",
      dataIndex: "cargoEnEmpresaExterna",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'participacionSocial', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const consultoriaColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
      className: "overflow-wrap-text"
    },
    {
      title: "Razón Social",
      dataIndex: "razonSocial",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de documento de identidad",
      dataIndex: "tipoDocEmp",
    },
    {
      title: "Documento Empresa",
      dataIndex: "docEmp",
    },
    {
      title: "Indique el tipo de servicio ofrecido",
      dataIndex: "tipoServicios",
    },
    format === "html" && !signed ? {
          title: "Acción",
          dataIndex: "",
          width: "8%",
          render: (text, record, index) => (
            <a onClick={handleDeleteAttrTable({attrTable: 'consultoria', index})}>
              <Icon type="delete" />
            </a>
          ),
        }
      : {},
  ];

  const interesEmpresaColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de documento de indentidad",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento de identidad",
      dataIndex: "doc",
    },
    {
      title: "Descripción de la situación",
      dataIndex: "descripcion",
      className: "overflow-wrap-text"
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'interesEmpresa', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const partesRelacionadasColumns = [
    {
      title: "Descripción de la situación",
      dataIndex: "descripcion",
      className: "overflow-wrap-text"
    },
    empresasGrupo?.length > 0 && {
      title: "Grupo de la Empresa",
      dataIndex: "grupo",
    },

    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'partesRelacionadas', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ].filter(Boolean)

  const exTrabajadorColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Cargo Ejercido",
      dataIndex: "cargo",
      className: "overflow-wrap-text"
    },
    {
      title: "Fecha de Salida",
      dataIndex: "fechaSalida",
    },
    empresasGrupo?.length > 0 &&{
      title: "Empresa del Grupo",
      dataIndex: "grupo",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'exTrabajador', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ].filter(Boolean)

  const relacionCompetenciaColumns = [
    {
      title: "Razón Social",
      dataIndex: "razonSocial",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento Empresa",
      dataIndex: "tipoDocEmp",
    },
    {
      title: "Documento Empresa",
      dataIndex: "docEmp",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'relacionCompetencia', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const relacionParentescoColumns = [
    {
      title: "Parentesco",
      dataIndex: "parentesco",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
    },
    empresasGrupo?.length > 0 && {
      title: "Empresa del Grupo",
      dataIndex: "grupoRelacionado",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'relacionParentesco', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ].filter(Boolean)

  const funcionariosPublicosColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
    },
    {
      title: "Categoría",
      dataIndex: "categoria",
    },
    {
      title: "Institución u Organismo Público",
      dataIndex: "razonSocial",
      className: "overflow-wrap-text"
    },
    {
      title: "Cargo ejercido",
      dataIndex: "cargoEnInstitucion",
      className: "overflow-wrap-text"
    },
    {
      title: "Fecha de término",
      dataIndex: "fechaTermino",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'funcionariosPublicos', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const responsabilidadPenalColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento Personal",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento Personal",
      dataIndex: "doc",
    },
    {
      title: "Descripción de la situación",
      dataIndex: "descripcion",
      className: "overflow-wrap-text"
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'responsabilidadPenal', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const otrosConflictosColumns = [
    {
      title: "Descripción de la situación",
      dataIndex: "descripcion",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'otrosConflictos', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }
  

const handleOnAddAttrTableLocal = (attrObj, attrTable, attrRecords='records') => {
  setIsValidate(true);
  if(handleOnAddAttrTable(attrObj, attrTable, attrRecords)) {
    registersStop[attrObj] = false;
  }
}

  const handleSubmit = async (e) => {

    //VALIDACIONES
    e.preventDefault();
    setIsValidate(true);
    setValidarRegistros(true);
    validateFieldsAndScroll([basicInformation.nombre.id, 
      basicInformation.tipoDoc.id, 
      basicInformation.doc.id,
      basicInformation.cargo.id,
      basicInformation.telefono.id,
      basicInformation.correo.id,
      basicInformation.esRepresentante.id,
    ])
    validateFieldsAndScroll([
      participacionSocial.hasRecords.id,
      consultoria.hasRecords.id,
      responsabilidadPenal.hasRecords.id,
      otrosConflictos.hasRecords.id,
      funcionariosPublicos.hasRecords.id,
      relacionParentesco.hasRecords.id,
      relacionCompetencia.hasRecords.id,
      partesRelacionadas.hasRecords.id,
      exTrabajador.hasRecords.id,
      interesEmpresa.hasRecords.id
    ]);

    // Validación para PARTICIPACIÓN SOCIAL O PERTENENCIA A UN DIRECTORIO
    if (participacionSocial.hasRecords.val && participacionSocial.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(partSocialObj).map(v => v.id));
      registersStop = { ...registersStop, [participacionSocial.records.id]: true };
    }

    // Validación para SERVICIOS DE ASESORÍA Y/O CONSULTORÍA
    if (consultoria.hasRecords.val && consultoria.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataConsultoria).map(v => v.id));
      registersStop = { ...registersStop, [consultoria.records.id]: true };
    }

    // Validación para RESPONSABILIDAD PENAL DE LAS PERSONAS JURÍDICAS
    if (responsabilidadPenal.hasRecords.val && responsabilidadPenal.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(resPenalObj).map(v => v.id));
      registersStop = { ...registersStop, [responsabilidadPenal.records.id]: true };
    }

    // Validación para OTROS CONFLICTOS DE INTERÉS
    if (otrosConflictos.hasRecords.val && otrosConflictos.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataOtrosConObj).map(v => v.id));
      registersStop = { ...registersStop, [otrosConflictos.records.id]: true };
    }

    // Validación para FUNCIONARIOS PÚBLICOS O PERSONAS EXPUESTAS POLÍTICAMENTE
    if (funcionariosPublicos.hasRecords.val && funcionariosPublicos.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataPepObj).map(v => v.id));
      registersStop = { ...registersStop, [funcionariosPublicos.records.id]: true };
    }

    // Validación para PARENTESCO
    if (relacionParentesco.hasRecords.val && relacionParentesco.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataRelParObj).map(v => v.id));
      registersStop = { ...registersStop, [relacionParentesco.records.id]: true };
    }

    // Validación para RELACIÓN CON LA COMPETENCIA
    if (relacionCompetencia.hasRecords.val && relacionCompetencia.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataCompObj).map(v => v.id));
      registersStop = { ...registersStop, [relacionCompetencia.records.id]: true };
    }

    // Validación para PARTES RELACIONADAS
    if (partesRelacionadas.hasRecords.val && partesRelacionadas.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(partRelObj).map(v => v.id));
      registersStop = { ...registersStop, [partesRelacionadas.records.id]: true };
    }

    // Validación para EX DIRECTOR O TRABAJADOR
    if (exTrabajador.hasRecords.val && exTrabajador.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(exTrabObj).map(v => v.id));
      registersStop = { ...registersStop, [exTrabajador.records.id]: true };
    }

    //Validación para INTERES EN LA PROPIEDAD O GESTIÓN DE LA EMPRESA
    if (interesEmpresa.hasRecords.val && interesEmpresa.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(intEmpObj).map(v => v.id));
      registersStop = { ...registersStop, [interesEmpresa.records.id]: true };
    }

    if (
      hasErrors(getFieldsError()) ||
      Object.values(registersStop).find((value) => value === true) !== undefined
    ) {
      notification["warning"]({
        message: t("messages.aml.missingRequiredField"),
      });
    } else {
      //FUNCION DE PROMISE Y GESTION DE RESPONSE
      sendDocument()
    }
  };

  return (
    <FormLayout view={match.params.view} >
      <div style={{ position: "relative" }}>
        {isLoading ? (
          <div className="form-header">
            <Row>
              <Col xs={9}></Col>
              <Col xs={6}>
                <div className="form-wrapper" style={{ textAlign: "center" }}>
                  <Spin style={{ fontColor: "#fff" }} size={"large"} />
                </div>
              </Col>
              <Col xs={9}></Col>
            </Row>
          </div>
        ) : (
          <div className="form-prov-aguasandinas">
            {apiForm !== null ? (
              <div
                className={
                  "form-content " + format + " " +(!isValidate ? " form-validate-messages" : "")
                }
              >
                <Form onSubmit={handleSubmit} className="form-form">
                  <Row
                    className="title-logo"
                    gutter={[0, 6]}
                    style={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      marginTop: "0px",
                      marginBottom: "0px",
                      textAlign: "left",
                      paddingTop: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Col
                      xs={24 - colLogo}
                      sm={24 - colLogo}
                      md={24 - colLogo}
                      lg={24 - colLogo}
                      xl={24 - colLogo}
                    >
                      <h3>FORMULARIO DE CONFLICTO DE INTERÉS - PROVEEDORES</h3>
                    </Col>
                    <Col
                      className="logo-col"
                      xs={colLogo}
                      sm={colLogo}
                      md={colLogo}
                      lg={colLogo}
                      xl={colLogo}
                    >
                      <Logo currentUser={{ userId: user.id, subclienteId: '0' }} isForm={true} />
                    </Col>
                  </Row>
                  <Row
                    className="date"
                    gutter={[0, 6]}
                    style={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      marginTop: "0px",
                      marginBottom: "0px",
                      textAlign: "left",
                      paddingBottom: "15px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Col span={2} style={{ textAlign: "left" }}>
                      Fecha:
                    </Col>
                    <Col span={4}>
                      <div
                        className="formDate"
                        style={{
                          marginLeft: "5px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "#e8e8e8",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {date}
                      </div>
                    </Col>
                    <Col span={13} style={{ textAlign: "right" }}>
                      Folio:
                    </Col>
                    <Col span={5}>
                      <div
                        className="formDate"
                        style={{
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "#e8e8e8",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "10px",
                          marginLeft: "8px",
                        }}
                      >
                        {apiForm.folio !== null ? apiForm.folio : ""}
                      </div>
                    </Col>
                  </Row>
                  {apiForm.status === "SENT" &&
                  (format === "html") ? (
                    <>
                      <br />
                      <h3 style={{ textAlign: "center" }}>
                        Estimado(a){/* {registerData.nombre} */}
                        {apiForm.recipient.record.name}, le informamos que su declaración fue correctamente
                        completada, agradecemos su tiempo y disposición.
                        <br />
                        Hemos enviado una copia de la declaración realizada al
                        correo electrónico registrado:<br/><br/>
                        {apiForm.recipient.record.email}
                      </h3>
                    </>
                  ) : (
                    <div style={{marginBottom: 20}}>
                      <Row
                        className="lineamiento subheader"
                        style={{ marginTop: "0px" }}
                      >
                        <Col xl={24}>DATOS DE LA PERSONA QUE COMPLETA LA DECLARACIÓN</Col>
                      </Row>
                      <Row gutter={[20]}>
                          {renderFormItemObj({
                            section: basicInformation.nombre,
                          })}
                          {renderFormItemObj({
                            section: basicInformation.tipoDoc,
                            options: tipoDocOptions
                          })}
                          {renderFormItemObj({
                            section: basicInformation.doc,
                            validator: docValidator(basicInformation.tipoDoc.val, "persona")
                          })}
                          {renderFormItemObj({
                            section: basicInformation.cargo,
                          })}
                      </Row>
                      <Row gutter={[20]}>
                          {renderFormItemObj({
                            section: basicInformation.telefono,
                            validator: numTelValidator
                          })}
                          {renderFormItemObj({
                            section: basicInformation.correo,
                            validator: emailValidator
                          })}
                          {renderFormItemObj({
                            section: basicInformation.esRepresentante,
                            options:[{text: "Si", val: "SI"}, {text: "No", val: "NO"}]
                          })}
                      </Row>
                      {basicInformation.esRepresentante.val === 'SI' &&
                        <p>
                          El representante legal se encuentra en pleno conocimiento y ha autorizado expresamente que mi persona complete en su nombre y de la empresa la presente declaración, haciéndome plenamente responsable de lo declarado y de la veracidad de la información proporcionada.
                        </p>
                      }
                      <Row
                        className="lineamiento subheader"
                        style={{ marginTop: "0px" }}
                      >
                        <Col xl={24}>INTRODUCCIÓN</Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          <p>
                            Yo {basicInformation?.nombre?.val}, cédula nacional de identidad número {basicInformation?.doc?.val}, en representación de {registerData.nombre}, rol único tributario número {apiForm.recipient.record.rut}, declaro bajo juramento que toda la información proporcionada en la presente Declaración Jurada, es del todo exacta, fehaciente y verdadera hasta donde tengo conocimiento y que no he omitido ningún tipo de información que pudiese ser relevante o pudiese producir un Conflicto de Interés para con {clientName}, asumiendo desde ya, las responsabilidades tanto civiles como penales que me corresponderían en caso de falsedad o inexactitud de esta Declaración Jurada. Se incluye dentro del concepto a todas aquellas sociedades relacionadas, de acuerdo al artículo 100 de la Ley N° 18.045.
                          </p>
                          <p>
                            Asimismo, declaro conocer, aceptar y haber leído, el Código de Ética, el Manual de Buenas Prácticas para Proveedores y el Manual de Prevención del Delito de {clientName}, en adelante “La Empresa”
                          </p>
                          <p>
                            También reconozco saber que tengo el deber de informar sobre cualquier situación que pueda dar origen a un potencial conflicto de interés en el ejercicio de la relación comercial.
                          </p>
                          <p>Por todo lo anterior informo y declaro (marque el casillero correspondiente):</p>
                        </Col>
                      </Row>
                      <Row className="lineamiento subheader">
                        <Col xl={24}>PARTICIPACIÓN SOCIAL O PERTENENCIA A UN DIRECTORIO</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          Declaro que tengo conocimiento de que algún director, gerente, ejecutivo principal o administrador de {registerData.nombre}, posee una participación social directa y/o indirecta, u ocupa el cargo de director, en La Empresa
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: participacionSocial.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: participacionSocial.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              readOnly={format==='pdf'}
                              onChange={({ target }) =>{
                                handlerOnChangeObjAttr(
                                  participacionSocial.hasRecords.id,
                                  target.value
                                )
                              }
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>
                      {participacionSocial.hasRecords.val && (
                        <>
                        {format === 'html' &&
                          <Row>
                            <Divider orientation='left'>
                              Datos de la persona que trabaja en {registerData.nombre}
                            </Divider>
                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: partSocialObj.nombre,
                              })}
                              {renderFormItemTable({
                                section: partSocialObj.tipoDoc,
                                options: tipoDocOptions,
                              })}
                              {renderFormItemTable({
                                section: partSocialObj.doc,
                                validator: docValidator(partSocialObj.tipoDoc.val, "persona")
                              })}
                              {renderFormItemTable({
                                section: partSocialObj.cargo,
                                options: cargosOptions
                              })}
                            </Row>

                            <Divider orientation='left'>
                              Cargo ocupado por: {partSocialObj.nombre.val}
                            </Divider>

                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: partSocialObj.cargoEnEmpresaExterna,
                                options: cargosOptions
                              })}
                              {empresasGrupo?.length > 0 && renderFormItemTable({
                                section: partSocialObj.grupo,
                                options: empresasGrupo?.map(empresa => ({ val: empresa, text: empresa })),
                              })}
                              {(partSocialObj.cargoEnEmpresaExterna.val === "Accionista" || partSocialObj.cargoEnEmpresaExterna.val === "Propietario") &&
                                renderFormItemTable({
                                  section: partSocialObj.tipoPropiedad,
                                  options: [
                                    { val: "Directa", text: "Directa" },
                                    { val: "Indirecta", text: "Indirecta" }
                                  ],
                                })
                              }
                              {(partSocialObj.cargoEnEmpresaExterna.val === "Accionista" || partSocialObj.cargoEnEmpresaExterna.val === "Propietario") &&
                                renderFormItemTable({
                                  section: partSocialObj.porcentajeParticipacion, // Campo para Porcentaje de Propiedad
                                })
                              }
                            </Row>
                            <Row gutter={[20]}>
                              {(partSocialObj.cargoEnEmpresaExterna.val === "Accionista" || partSocialObj.cargoEnEmpresaExterna.val === "Propietario") && partSocialObj.tipoPropiedad.val === "Indirecta" &&
                                renderFormItemTable({
                                  section: partSocialObj.nombreEmpIndirecta, // Campo para Nombre de Empresa Indirecta
                                })
                              }
                       
                              {(partSocialObj.cargoEnEmpresaExterna.val === "Accionista" || partSocialObj.cargoEnEmpresaExterna.val === "Propietario") && partSocialObj.tipoPropiedad.val === "Indirecta" &&
                                renderFormItemTable({
                                  section: partSocialObj.tipoDocEmpIndirecta, // Campo para Tipo de Documento de Empresa Indirecta
                                  cols: 8,
                                  options: tipoDocOptions
                                })
                              }
                              {(partSocialObj.cargoEnEmpresaExterna.val === "Accionista" || partSocialObj.cargoEnEmpresaExterna.val === "Propietario") && partSocialObj.tipoPropiedad.val === "Indirecta" &&
                                renderFormItemTable({
                                  section: partSocialObj.docEmpIndirecta, // Campo para Rut de Empresa Indirecta
                                  validator: docValidator(partSocialObj.tipoDocEmpIndirecta.val, "empresa") // Validador para Rut
                                })
                              }
                            </Row>
                          </Row>
                        }
                        {participacionSocial.records.val.length < 1 &&
                          validarRegistros && (
                            <Col
                            span={24}
                            style= {{color: 'red'}}
                            className="missing-registers ant-form-explain"
                            >
                            {t("messages.aml.registersRequired")}
                            </Col>
                        )}
                        {format === 'html' &&
                          <Row>
                            <Col className={participacionSocial.records.val.length < 1 && "addRelation"} xl={3}>
                              <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTableLocal('partSocialObj', 'participacionSocial')} icon="plus" > Añadir </Button>
                            </Col>
                            <Col className={participacionSocial.records.val.length < 1 && "addRelation"} xl={3}>
                              <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('partSocialObj')}> Limpiar </Button>
                            </Col>
                          </Row>
                        }
                        
                          {participacionSocial.records.val.length > 0 && format === "html" ? (
                            <Table columns={partSocialColumns} dataSource={participacionSocial.records.val} size="middle" pagination={false} ></Table>
                          ) : (
                            toDescriptionsPdf(participacionSocial.records.val, partSocialObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      <Row
                        className="lineamiento subheader"
                      >
                        <Col xl={24}>SERVICIOS DE ASESORÍA Y/O CONSULTORÍA</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                        Declaro que tengo conocimiento de que algún director, gerente, ejecutivo principal o administrador de {registerData.nombre}, presta servicios de asesoría y/o consultoría a La Empresa
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: consultoria.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: consultoria.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(
                                  consultoria.hasRecords.id,
                                  target.value
                                )
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })
                        }
                      </Row>
                      {consultoria.hasRecords.val && (
                      <>
                        {format === 'html' &&
                        <>
                          <Row gutter={[20]}>
                            <Divider orientation='left'>
                              Datos de la persona que trabaja en {registerData.nombre}
                            </Divider>
                            {renderFormItemTable({
                              section: dataConsultoria.nombre,
                            })}
                            {renderFormItemTable({
                              section: dataConsultoria.tipoDoc,
                              options: tipoDocOptions
                            })}
                            {renderFormItemTable({
                              section: dataConsultoria.doc,
                              validator: docValidator(dataConsultoria.tipoDoc.val, "persona")
                            })}
                            {renderFormItemTable({
                              section: dataConsultoria.cargo,
                              options: cargosOptions
                            })}
                          </Row>
                          <Row gutter={[20]}>
                            <Divider orientation='left'>
                              Datos de la empresa en la que presta servicios {dataConsultoria.nombre.val}
                            </Divider>
                            {renderFormItemTable({
                              section: dataConsultoria.razonSocial,
                            })}
                            {renderFormItemTable({
                              section: dataConsultoria.tipoDocEmp,
                              options: tipoDocOptions
                            })}
                            {renderFormItemTable({
                              section: dataConsultoria.docEmp,
                              validator: docValidator(dataConsultoria.tipoDocEmp.val, "empresa")
                            })}
                            {renderFormItemTable({
                              section: dataConsultoria.tipoServicios,
                            })}
                          </Row>
                          <Row gutter={[20]}>
                            {empresasGrupo?.length > 0 && renderFormItemTable({
                              section: dataConsultoria.grupo,
                              options: empresasGrupo?.map(empresa => ({ val: empresa, text: empresa })),
                            })}
                          </Row>
                          
                          {consultoria.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                              span={24}
                              style= {{color: 'red'}}
                              className="missing-registers ant-form-explain"
                              >
                              {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          <Row>
                            <Col className={consultoria.records.val.length < 1 && "addRelation"} xl={3}>
                              <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTableLocal('dataConsultoria', 'consultoria')} icon="plus" > Añadir </Button>
                            </Col>
                            <Col className={consultoria.records.val.length < 1 && "addRelation"} xl={3}>
                              <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('dataConsultoria')}> Limpiar </Button>
                            </Col>
                          </Row>
                        </>
                        }
                        {consultoria.records.val.length > 0 && format === "html" ?
                          <Table columns={consultoriaColumns} dataSource={consultoria.records.val} size="middle" pagination={false} ></Table>
                          :
                          toDescriptionsPdf( consultoria.records.val, dataConsultoria, apiForm.typeSol )
                        }
                      </>
                      )}

                     {/* RELACION CON LA COMPETENCIA */}
                     <Row className="lineamiento subheader">
                        <Col xl={24}>RELACIÓN CON LA COMPETENCIA</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <div>
                            Declaro que tengo conocimiento de que algún director, socio, gerente, accionista, ejecutivo principal, asesor, administrador o colaborador de {registerData.nombre}, desarrollan por sí, o en cumplimiento de una prestación de servicios, actividades comerciales que puedan competir con las realizadas por {clientName}
                          </div>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: relacionCompetencia.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: relacionCompetencia.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(relacionCompetencia.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {relacionCompetencia.hasRecords.val && (
                        <>
                        {format === 'html' &&
                          <>
                            <Row>
                              <Divider orientation="left">
                                Datos de la persona que trabaja en {registerData.nombre}
                              </Divider>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataCompObj.nombre,
                                })}

                                {renderFormItemTable({
                                  section: dataCompObj.tipoDoc,
                                  options: tipoDocOptions,
                                })}

                                {renderFormItemTable({
                                  section: dataCompObj.doc,
                                  validator: docValidator(dataCompObj.tipoDoc.val, "persona")
                                })}
                                {renderFormItemTable({
                                  section: dataCompObj.cargo,
                                  options: cargosOptions.concat([{val: 'Colaborador', text: 'Colaborador'}]),
                                })}
                              </Row>
                              <Divider orientation="left">
                                Datos de la empresa en la que presta servicios {dataCompObj.nombre.val}, la cual es competidora de {clientName}
                              </Divider>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataCompObj.razonSocial,
                                })}

                                {renderFormItemTable({
                                  section: dataCompObj.tipoDocEmp,
                                  options: tipoDocOptions,
                                })}

                                {renderFormItemTable({
                                  section: dataCompObj.docEmp,
                                  validator: docValidator(dataCompObj.tipoDocEmp.val, "empresa"),
                                })}
                              </Row>
                            </Row>
                            {relacionCompetencia.records.val.length < 1 &&
                              validarRegistros && (
                                <Col
                                  span={24}
                                  style= {{color: 'red'}}
                                  className="missing-registers ant-form-explain"
                                >
                                  {t("messages.aml.registersRequired")}
                                </Col>
                            )}
                            <Row>
                              <Col className={relacionCompetencia.records.val.length < 1 && "addRelation"} xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('dataCompObj', 'relacionCompetencia')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className={relacionCompetencia.records.val.length < 1 && "addRelation"} xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('dataCompObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          </>
                        }

                          {relacionCompetencia.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={relacionCompetenciaColumns}
                              dataSource={relacionCompetencia.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(relacionCompetencia.records.val, dataCompObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      <Row className="lineamiento subheader">
                        <Col xl={24}>PARENTESCO</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <div>
                            Declaro que tengo conocimiento de que algún director, gerente, ejecutivo principal o administrador de {registerData.nombre}, tiene un grado de parentesco con Directores, Gerentes, Socios, Administradores, Ejecutivos Principales de La Empresa
                          </div>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: relacionParentesco.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: relacionParentesco.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(relacionParentesco.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {relacionParentesco.hasRecords.val && (
                        <>
                          {format === 'html' &&
                            <Row>
                                <Divider orientation="left">
                                  Datos de la persona que trabaja en {registerData.nombre}
                                </Divider>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataRelParObj.nombre,
                                })}

                                {renderFormItemTable({
                                  section: dataRelParObj.tipoDoc,
                                  options: tipoDocOptions
                                })}

                                {renderFormItemTable({
                                  section: dataRelParObj.doc,
                                  validator: docValidator(dataRelParObj.tipoDoc.val, "persona")
                                })}
                                {renderFormItemTable({
                                  section: dataRelParObj.cargo,
                                  options: cargosOptions
                                })}
                                
                              </Row>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataRelParObj.parentesco,
                                  options: relationshipOptions.slice(1)
                                })}
                              </Row>
                              <Divider orientation="left">
                                Datos del pariente relacionado a {clientName}
                              </Divider>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataRelParObj.nombreRelacionado,
                                })}

                                {renderFormItemTable({
                                  section: dataRelParObj.tipoDocRelacionado,
                                  options: tipoDocOptions
                                })}

                                {renderFormItemTable({
                                  section: dataRelParObj.docRelacionado,
                                  validator: docValidator(dataRelParObj.tipoDocRelacionado.val, "persona")
                                })}
                                {renderFormItemTable({
                                  section: dataRelParObj.cargoRelacionado,
                                  options: cargosOptions
                                })}
                              </Row>
                              <Row gutter={[20]}>
                                {empresasGrupo?.length > 0 && renderFormItemTable({
                                  section: dataRelParObj.grupoRelacionado,
                                  options: empresasGrupo?.map(empresa => ({ val: empresa, text: empresa })),
                                })}
                                
                              </Row>
                            </Row>
                          }
                          {relacionParentesco.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                                span={24}
                                style= {{color: 'red'}}
                                className="missing-registers ant-form-explain"
                              >
                                {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          {format === 'html' &&
                            <Row>
                              <Col className={relacionParentesco.records.val.length < 1 && "addRelation"} xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('dataRelParObj', 'relacionParentesco')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className={relacionParentesco.records.val.length < 1 && "addRelation"} xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('dataRelParObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          }
                        
                          {relacionParentesco.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={relacionParentescoColumns}
                              dataSource={relacionParentesco.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(relacionParentesco.records.val, dataRelParObj, apiForm.typeSol)
                          )}
                        </>
                      )}
                      <Row className="lineamiento subheader">
                        <Col xl={24}>PARTES RELACIONADAS</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <p>¿Es usted parte relacionada?</p> <p>En caso de ser afirmativo por favor indicar la empresa relacionada</p>

                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: partesRelacionadas.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: partesRelacionadas.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(partesRelacionadas.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {partesRelacionadas.hasRecords.val && (
                        <>
                          {format === 'html' &&
                            <>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: partRelObj.descripcion,
                                  cols: 24
                                })}
                              </Row>
                              <Row gutter={[20]}>
                                {empresasGrupo?.length > 0 && renderFormItemTable({
                                  section: partRelObj.grupo,
                                  options: empresasGrupo?.map(empresa => ({ val: empresa, text: empresa })),
                                })}
                              </Row>
                              {partesRelacionadas.records.val.length < 1 &&
                                validarRegistros && (
                                  <Col
                                  span={24}
                                  style= {{color: 'red'}}
                                  className="missing-registers ant-form-explain"
                                  >
                                  {t("messages.aml.registersRequired")}
                                  </Col>
                              )}
                              <Row>
                                <Col className={partesRelacionadas.records.val.length < 1 && "addRelation"} xl={3}>
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    onClick={() => handleOnAddAttrTableLocal('partRelObj', 'partesRelacionadas')}
                                    icon="plus"
                                  >
                                    Añadir
                                  </Button>
                                </Col>
                                <Col className={partesRelacionadas.records.val.length < 1 && "addRelation"} xl={3}>
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    icon="delete"
                                    onClick={() => handleOnClear('partRelObj')}
                                  >
                                    Limpiar
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          }

                          {partesRelacionadas.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={partesRelacionadasColumns}
                              dataSource={partesRelacionadas.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(partesRelacionadas.records.val, partRelObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      <Row className="lineamiento subheader">
                        <Col xl={24}>EX DIRECTOR O TRABAJADOR</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          Declaro que tengo conocimiento de que algún director, gerente, ejecutivo principal o administrador de {registerData.nombre} ha ejercido funciones en La Empresa, dentro de los últimos 18 meses.
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: exTrabajador.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: exTrabajador.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(exTrabajador.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {exTrabajador.hasRecords.val && (
                        <>
                        {format === 'html' &&
                          <>
                          <Row>
                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: exTrabObj.nombre,
                              })}

                              {renderFormItemTable({
                                section: exTrabObj.tipoDoc,
                                options: tipoDocOptions,
                              })}

                              {renderFormItemTable({
                                section: exTrabObj.doc,
                                validator: docValidator(exTrabObj.tipoDoc.val,"persona")
                              })}

                              {renderFormItemTable({
                                section: exTrabObj.cargo,
                                options: cargosOptions
                              })}
                            </Row>
                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: exTrabObj.fechaSalida,
                              })}
                              {empresasGrupo?.length > 0 && renderFormItemTable({
                                  section: exTrabObj.grupo,
                                  options: empresasGrupo?.map(empresa => ({ val: empresa, text: empresa })),
                              })}
                            </Row>
                          </Row>
                            {exTrabajador.records.val.length < 1 &&
                              validarRegistros && (
                                <Col
                                  span={24}
                                  style= {{color: 'red'}}
                                  className="missing-registers ant-form-explain"
                                >
                                  {t("messages.aml.registersRequired")}
                                </Col>
                            )}
                            <Row>
                              <Col className={exTrabajador.records.val.length < 1 && "addRelation"} xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('exTrabObj', 'exTrabajador')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className={exTrabajador.records.val.length < 1 && "addRelation"}xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('exTrabObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          </>
                        }

                          {exTrabajador.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={exTrabajadorColumns}
                              dataSource={exTrabajador.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(exTrabajador.records.val, exTrabObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      {/* RELACION CON PROVEEDORES */}
                      <Row className="lineamiento subheader">
                        <Col xl={24}>INTERES EN LA PROPIEDAD O GESTIÓN DE LA EMPRESA</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <div>
                            Declaro que tengo conocimiento de que algún director, gerente, ejecutivo principal o administrador de {registerData.nombre} tiene interés en la propiedad o gestión de La Empresa.
                          </div>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: interesEmpresa.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: interesEmpresa.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(interesEmpresa.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {interesEmpresa.hasRecords.val && (
                        <>
                        {format === 'html' &&
                          <Row>
                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: intEmpObj.nombre,
                              })}

                              {renderFormItemTable({
                                section: intEmpObj.tipoDoc,
                                options: tipoDocOptions,
                              })}

                              {renderFormItemTable({
                                section: intEmpObj.doc,
                                validator: docValidator(intEmpObj.tipoDoc.val, 'persona')
                              })}
                              {renderFormItemTable({
                                section: intEmpObj.descripcion,
                                cols: 24
                              })}
                            </Row>
                          </Row>
                          }
                          {interesEmpresa.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                              span={24}
                              style= {{color: 'red'}}
                              className="missing-registers ant-form-explain"
                              >
                              {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          {format === 'html' &&
                          <Row>
                            <Col className={interesEmpresa.records.val.length < 1 && "addRelation"} xl={3}>
                              <Button
                                type="primary"
                                htmlType="button"
                                onClick={() => handleOnAddAttrTableLocal('intEmpObj', 'interesEmpresa')}
                                icon="plus"
                              >
                                Añadir
                              </Button>
                            </Col>
                            <Col className={interesEmpresa.records.val.length < 1 && "addRelation"} xl={3}>
                              <Button
                                type="primary"
                                htmlType="button"
                                icon="delete"
                                onClick={() => handleOnClear('intEmpObj')}
                              >
                                Limpiar
                              </Button>
                            </Col>
                          </Row>
                          }

                          {interesEmpresa.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={interesEmpresaColumns}
                              dataSource={interesEmpresa.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(interesEmpresa.records.val, intEmpObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      <Row className="lineamiento subheader">
                        <Col xl={24}>FUNCIONARIOS PÚBLICOS O PERSONAS EXPUESTAS POLÍTICAMENTE</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          Declaro que tengo conocimiento de que algún director, gerente, ejecutivo principal o administrador de {registerData.nombre} es o ha sido un empleado o funcionario público, tanto en la administración central del Estado como en instituciones o empresas fiscales o semifiscales, municipales, autónomas u organismos creados por el Estado o bajo su dependencia; o es una Persona Expuesta Políticamente (PEP) o ha desempeñado funciones públicas destacadas durante los últimos 12 meses.
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: funcionariosPublicos.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: funcionariosPublicos.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(funcionariosPublicos.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {funcionariosPublicos.hasRecords.val && (
                        <>
                          {format === 'html' &&
                            <Row>
                              <Divider orientation='left'>
                                Datos de la persona que trabaja en {registerData.nombre}
                              </Divider>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataPepObj.nombre,
                                })}

                                {renderFormItemTable({
                                  section: dataPepObj.tipoDoc,
                                  options: tipoDocOptions,  // Opciones para el select de tipo de documento
                                })}

                                {renderFormItemTable({
                                  section: dataPepObj.doc,
                                  validator: docValidator(dataPepObj.tipoDoc.val, "persona")
                                })}
                                {renderFormItemTable({
                                  section: dataPepObj.cargo,
                                  options: cargosOptions
                                })}
                              </Row>
                              <Divider orientation='left'>
                                Datos de la Institución u Organismo Público relacionada a {dataPepObj.nombre.val}
                              </Divider>
                              <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: dataPepObj.categoria,
                                options: [{text: 'Funcionario Público', val: 'Funcionario Público'}, {text: 'PEP', val: 'PEP'}]  // Opciones para categoría de funcionario público
                              })}
                              {renderFormItemTable({
                                section: dataPepObj.razonSocial,
                              })}
                              {renderFormItemTable({
                                section: dataPepObj.cargoEnInstitucion,
                              })}
                              {renderFormItemTable({
                                section: dataPepObj.fechaTermino,
                              })}
                              </Row>
                            </Row>
                          }
                          {funcionariosPublicos.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                                span={24}
                                style= {{color: 'red'}}
                                className="missing-registers ant-form-explain"
                              >
                                {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          {format ==='html' && 
                            <Row>
                              <Col className={funcionariosPublicos.records.val.length < 1 && "addRelation"} xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('dataPepObj', 'funcionariosPublicos')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className={funcionariosPublicos.records.val.length < 1 && "addRelation"} xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('dataPepObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          }
                          {funcionariosPublicos.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={funcionariosPublicosColumns}
                              dataSource={funcionariosPublicos.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(funcionariosPublicos.records.val, dataPepObj, apiForm.typeSol)
                            )}
                        </>
                      )}

                      <Row className="lineamiento subheader">
                        <Col xl={24}>RESPONSABILIDAD PENAL DE LAS PERSONAS JURÍDICAS</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          Declaro que tengo conocimiento de que algún director, gerente, ejecutivo principal o administrador de {registerData.nombre} ha sido procesado o condenado por alguno de los delitos contenidos en la Ley N° 20.393 sobre responsabilidad penal de las personas jurídicas.
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: responsabilidadPenal.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: responsabilidadPenal.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(responsabilidadPenal.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {responsabilidadPenal.hasRecords.val &&  (
                        <>
                          {format === 'html' &&
                            <>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: resPenalObj.nombre,
                                })}

                                {renderFormItemTable({
                                  section: resPenalObj.tipoDoc,
                                  options: tipoDocOptions,  // Opciones para tipo de documento
                                })}

                                {renderFormItemTable({
                                  section: resPenalObj.doc,
                                  validator: docValidator(resPenalObj.tipoDoc.val, "persona")
                                })}
                              </Row>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: resPenalObj.descripcion,
                                  cols: 24
                                })}
                              </Row>
                            </>
                          }
                          {responsabilidadPenal.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                                span={24}
                                style= {{color: 'red'}}
                                className="missing-registers ant-form-explain"
                              >
                                {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          {format ==='html' && 
                            <Row>
                              <Col className={responsabilidadPenal.records.val.length < 1 && "addRelation"} xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('resPenalObj', 'responsabilidadPenal')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className={responsabilidadPenal.records.val.length < 1 && "addRelation"} xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('resPenalObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          }
                          {responsabilidadPenal.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={responsabilidadPenalColumns}
                              dataSource={responsabilidadPenal.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(responsabilidadPenal.records.val, resPenalObj, apiForm.typeSol)
                          )}
                        </>
                      )}
                      <Row className="subheader">
                        CONOCIMIENTO DE LA LEY N°20.393 SOBRE “RESPONSABILIDAD PENAL DE LAS PERSONAS JURÍDICAS"
                      </Row>
                      <Row className="summary">
                        <Col span={24}>
                          <p>
                            Declaro estar en conocimiento de que {clientName} ha adoptado un modelo de prevención de delitos de acuerdo a la Ley N°20.393 de “Responsabilidad Penal de las Personas Jurídicas, y me comprometo a no cometer o participar en la comisión de ninguno de los delitos señalados en la citada Ley u otros que pudiesen incorporarse en el futuro, así como cumplir procesos y controles establecidos para dicho fin.
                          </p>
                          <p>
                            Declaro tener la obligación de comunicar al Encargado de Prevención de Delitos, todo acto o conducta que revista caracteres de delito del cual tome conocimiento, mediante el Canal de Denuncias dispuesto por {clientName}
                          </p>
                        </Col>
                      </Row>

                      <Row className="lineamiento subheader">
                        <Col xl={24}>OTROS CONFLICTOS DE INTERÉS</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          Declaro que tengo conocimiento de algún otro conflicto de interés no abordado en las preguntas anteriores que pudiera afectar o influir de cualquier forma en la relación de {registerData.nombre} con La Empresa.
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: otrosConflictos.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: otrosConflictos.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(otrosConflictos.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {otrosConflictos.hasRecords.val && (
                        <>
                          {format === 'html' && 
                            <Row>
                              <Row>
                                {renderFormItemTable({
                                  section: dataOtrosConObj.descripcion,
                                  cols: 24
                                })}
                              </Row>
                            </Row>
                          }
                          {otrosConflictos.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                                span={24}
                                style= {{color: 'red'}}
                                className="missing-registers ant-form-explain"
                              >
                                {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          {format ==='html' && 
                            <Row>
                              <Col className={otrosConflictos.records.val.length < 1 && "addRelation"} xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('dataOtrosConObj', 'otrosConflictos')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className={otrosConflictos.records.val.length < 1 && "addRelation"} xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('dataOtrosConObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          }
                          
                        
                          {otrosConflictos.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={otrosConflictosColumns}
                              dataSource={otrosConflictos.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(otrosConflictos.records.val, dataOtrosConObj, apiForm.typeSol)
                          )}
                        </>
                      )}
                      <Row className="subheader">
                        PROHIBICIÓN DE USO DE INFORMACIÓN CONFIDENCIAL
                      </Row>
                      <Row className="summary">
                        <Col span={24}>
                          <p>
                            Declaro tener conocimiento de que se encuentra prohibido, divulgar, revelar o consentir que otra persona acceda a un secreto comercial de {clientName}, y que esto constituye un delito contemplado en el artículo 284 bis del Código Penal.
                          </p>
                        </Col>
                      </Row>
                      
                      <Row className="subheader">
                        <Col xl={24}>
                          DECLARACIÓN DE RESPONSABILIDAD
                        </Col>
                      </Row>
                      <Row className="summary">
                        <p>
                          Certifico que mis respuestas están completas y son correctas a mi mejor saber y entender. Además, acepto que tengo la obligación permanente de comunicar a la brevedad y por escrito a {clientName}, en el evento que se originen cambios a la presente declaración antes de la siguiente presentación, con el objeto de que se proceda a actualizar los antecedentes entregados, y si fuera el caso, también tengo la obligación de inhibirme de tomar decisiones que pudieran verse afectadas por un posible conflicto de interés declarado mientras este no se resuelva.
                        </p>
                        <p>
                          Asimismo, faculto y declaro estar en conocimiento de que {clientName}, por si o a través de terceros, podría eventualmente, y de manera aleatoria o basado en patrones de riesgo, verificar la información proporcionada.
                        </p>
                      </Row>
                    </div>
                  )}
                  {apiForm.status !== "SENT" && format === 'html' &&
                    <Row className="button-row">
                      <Col className="submitTrabajador" xl={24}>
                        <Button type="primary" onClick={handleSubmit} icon='file-protect'>
                          { hasSign ? 'Firmar' : 'Enviar' }
                        </Button>
                      </Col>
                    </Row>
                  }
                </Form>
              </div>
            ) : (
              <h2 style={{ textAlign: "center" }}>Formulario no encontrado</h2>
            )}
          </div>
        )}
      </div>
    </FormLayout>
  );
};

export default withRouter(Form.create()(FormProv));
