export const prodActOptions = [
  { name: "Accesorios", val: "Accesorios" },
  { name: "Alimentos y bebidas", val: "Alimentos y bebidas" },
  { name: "Áreas comunes", val: "Áreas comunes" },
  { name: "Automóviles", val: "Automóviles" },
  { name: "Belleza y cuidado personal", val: "Belleza y cuidado personal" },
  { name: "Bodegas", val: "Bodegas" },
  { name: "Cajas de compensación", val: "Cajas de compensación" },
  { name: "Calzado y vestuario", val: "Calzado y vestuario" },
  { name: "Casinos", val: "Casinos" },
  { name: "Cine", val: "Cine" },
  { name: "Clínicas y centros de salud", val: "Clínicas y centros de salud" },
  { name: "Computación y electrónica", val: "Computación y electrónica" },
  { name: "Deporte y outdoor", val: "Deporte y outdoor" },
  { name: "Educación", val: "Educación" },
  { name: "Entretenimiento familiar", val: "Entretenimiento familiar" },
  { name: "Entretenimiento adulto (discotecas y pubs)", val: "Entretenimiento adulto (discotecas y pubs)" },
  { name: "Farmacia", val: "Farmacia" },
  { name: "Food court", val: "Food court" },
  { name: "Gimnasios", val: "Gimnasios" },
  { name: "Hipódromos", val: "Hipódromos" },
  { name: "Hogar y decoración", val: "Hogar y decoración" },
  { name: "Hotel", val: "Hotel" },
  { name: "Inmobiliarias y constructoras", val: "Inmobiliarias y constructoras" },
  { name: "Joyería y relojería", val: "Joyería y relojería" },
  { name: "Juegos de azar / casa de cambio", val: "Juegos de azar / casa de cambio" },
  { name: "Maletería", val: "Maletería" },
  { name: "Mejoramiento del hogar", val: "Mejoramiento del hogar" },
  { name: "Oficinas", val: "Oficinas" },
  { name: "Organizaciones de caridad benéficas o sin ánimos de lucro", val: "Organizaciones de caridad benéficas o sin ánimos de lucro" },
  { name: "Otros comidas", val: "Otros comidas" },
  { name: "Otros retail", val: "Otros retail" },
  { name: "Publicidad", val: "Publicidad" },
  { name: "Restaurantes", val: "Restaurantes" },
  { name: "Servicios", val: "Servicios" },
  { name: "Servicios financieros y bancos", val: "Servicios financieros y bancos" },
  { name: "Supermercado", val: "Supermercado" },
  { name: "Telecomunicaciones", val: "Telecomunicaciones" },
  { name: "Terminal de buses", val: "Terminal de buses" },
  { name: "Tienda departamental", val: "Tienda departamental" },
  { name: "Usuarios de zonas francas", val: "Usuarios de zonas francas" }
]

export const relationshipOptions = [
  { val: "Mi Persona", text: "Mi Persona" },
  { val: "Cónyuge", text: "Cónyuge" },
  { val: "Conviviente civil", text: "Conviviente civil" },
  { val: "Padre", text: "Padre" },
  { val: "Hermano(a)", text: "Hermano(a)" },
  { val: "Madre", text: "Madre" },
  { val: "Hijo(a)", text: "Hijo(a)" },
  { val: "Abuelo(a)", text: "Abuelo(a)" },
  { val: "Nieto(a)", text: "Nieto(a)" },
  { val: "Suegro(a)", text: "Suegro(a)" },
  { val: "Hijo(a) del Cónyuge que no sean suyos", text: "Hijo(a) del Cónyuge que no sean suyos" },
  { val: "Abuelo(a) del cónyuge", text: "Abuelo(a) del cónyuge" },
  { val: "Nieto(a) del cónyuge", text: "Nieto(a) del cónyuge" },
  { val: "Yerno", text: "Yerno" },
  { val: "Nuera", text: "Nuera" },
  { val: "Tío(a)", text: "Tío(a)" },
  { val: "Primo(a)", text: "Primo(a)" },
  { val: "Sobrino(a)", text: "Sobrino(a)" },
  { val: "Bisabuelo(a)", text: "Bisabuelo(a)" },
  { val: "Bisnieto(a)", text: "Bisnieto(a)" },
  { val: "Bisabuelo(a) del cónyuge", text: "Bisabuelo(a) del cónyuge" },
  { val: "Cuñado(a)", text: "Cuñado(a)" },
  { val: "Sobrino(a) del Cónyuge", text: "Sobrino(a) del Cónyuge" },
]

export const cargosOptions = [
  { val: "Director", text: "Director" },
  { val: "Socio", text: "Socio" },
  { val: "Accionista", text: "Accionista" },
  { val: "Gerente", text: "Gerente" },
  { val: "Ejecutivo Principal", text: "Ejecutivo Principal" },
  { val: "Administrador", text: "Administrador" },
  { val: "Asesor", text: "Asesor" },
]

export const tipoDocOptions = [
  { val: "Chile-Rut", text: "Chile-Rut" }, 
  { val: "Otros", text: "Otros" }
]

export const tipoEmpresaOptions = [
  { val: "Proveedor", text: "Proveedor" },
  { val: "Contratista", text: "Contratista" },
  { val: "Cliente", text: "Cliente" },
]

export const pepTypeOptions = [
  {val: 'PEP', text: 'PEP'},
  {val: 'Funcionario Publico', text: 'Funcionario Público'}
]

export const tipoFundacionOptions = [
  { val: "Fundación", text: "Fundación" },
  { val: "Corporación", text: "Corporación" },
  { val: "Institución de Beneficencia o Sin Fines de Lucro", text: "Institución de Beneficencia o Sin Fines de Lucro" },
]

export const radioBtnIds = [
  'hasOutdoorActivities',
  'hasInterlocking',
  'hasRelLabAnterior',
  'hasCompetencia',
  'hasParentesco',
  'hasPep',
  "hasRelacionProveedor",
  'hasFundaciones',
  'hasOtherConflicts',
  'hasOtherConflictsv2',
  'hasPemp',
  'hasPsoc',
];