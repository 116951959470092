import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next'
import './HistoryModal.scss'
import moment from 'moment';
import {ReportService} from '../../../../services'

import { Modal, Button, Pagination, Row, Col, Spin, message } from 'antd';

const HistoryModal = ({ closeHandler, type, schema, data, recordId, flowType, formId }) => {

  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState(1);
  const [firstIndex, setFirstIndex] = useState(0);
  const [maxItemsPerPage, setMaxItemsPerPage] = useState(5);
  const [lastIndex, setLastIndex] = useState(maxItemsPerPage);
  const [indexLoading, setIndexLoading] = useState(null)
    
  const handleListDownload = async (id, risk, fileName, index, indexFile) => {
      setIndexLoading(index +'-'+ indexFile)
      if(flowType === "attachment"){
        let obj = {formId, fileName}
        await ReportService.read('/cdi/getFileFormComment', obj, null, fileName, null)
      }else{
        let obj = {id, risk, fileName}
        await ReportService.read('/getFileRisk', obj, null, fileName, null)
      }
      setIndexLoading(null)
  }

  const handleChangePage = (page, pageSize) => {
    let first = (page-1) * pageSize
    setFirstIndex(first)
    let last = page * pageSize
    if(last > data.length) {
      last = data.length
    }
    setLastIndex(last)
    setCurrentPage(page);
  }

  const handleFileCommentDownload = (commentId, fileName) => {
    message.loading('Descargando archivo', 1.5)
      ReportService.read('/getRecordCommentFile/'+commentId, null, null, fileName)
  }

  return (
    <Modal
      title={type}
      width={1200}
      style={{ top: 10 }}
      wrapClassName="historyCDIModal"
      visible={true}
      onCancel={closeHandler}
      footer={[<Button onClick={closeHandler}>{t('messages.aml.btnClose')}</Button>]}
    >
      <Row className="title">
        {
          schema.map(item =>
            <Col span={item.cols}>
              {item.title}
            </Col>
          )
        }
      </Row>
      { data.slice(firstIndex, lastIndex).map((row, index) => 
        <div style={{padding: '0px 0px 20px 0px'}}>
          <div className="data-item">
            <Row>
              {
                schema.map(item => 
                  <Col span={item.cols}>
                    {item.dataIndex === 'creationDate' ? 
                      moment(row[item.dataIndex]).format('DD/MM/YYYY HH:mm:ss') 
                      : item.dataIndex === 'risk' ?
                          t(`messages.aml.risk.${row[item.dataIndex]}`) 
                      : item.dataIndex === 'fileNameComment' ?
                        <Button 
                          type="link" 
                          block 
                          onClick={() => handleFileCommentDownload(row.commentId, row[item.dataIndex])}
                          >
                            {row[item.dataIndex]}
                        </Button>
                        
                      : row[item.dataIndex]
                    }
                  </Col>
                )
              }
            </Row>
            {row.comments !== undefined &&
              <Row>
                <Col style={{ marginTop: 'auto', marginBottom: 'auto' }} xs={24}>
                    <pre className="comments">{(row.comments || "Sin comentarios")}</pre>
                </Col>
              </Row>
            }
            {row.files !== undefined &&
              <Row>
                <Col>
                  { row.files && row.files.map((file, indexFile) =>
                    <Row>
                      <Col span={6}>Documento { indexFile+1}</Col>
                      <Col span={10}>{file.name}</Col>
                      <Col span={6}>
                        { indexLoading === (index+'-'+indexFile) ? <Spin />
                        :
                          <a onClick={() => handleListDownload(recordId, row.risk, file.originalName, index, indexFile)}>Ver documento</a>
                        }
                      </Col>
                    </Row>
                    )
                  }
                </Col>
              </Row>
            }
          </div>
        </div>
      )}
        <Pagination onChange={handleChangePage} current={currentPage} pageSize={maxItemsPerPage} total={data.length} style={{textAlign: 'center'}} />
    </Modal>
      )
}

export default HistoryModal;
