import { useAuth } from 'react-oidc-context';
import { useEffect } from "react";
import { Spin } from "antd";

export default function PrivateOauth({successHandler}) {
  const auth = useAuth();
  const isLoading = auth.isLoading
  const isAuthenticated = auth.isAuthenticated
  const user = auth.user?.profile

  useEffect(() => {
    debugger
    if(isAuthenticated && user) {
      let email = user.preferred_username
      if(!email) {
        email = user.email
      }
      successHandler(email)
    }
    
  }, [isAuthenticated]);

  const logout = () => {
    auth.removeUser()
  }

  return (
    <div className="root">
      <div>
        <h1>Log in with Oauth</h1>
        { isLoading && <div><Spin/></div>}
        { isAuthenticated ?
          <>
            <div>{user.name}</div>
            <input type="button" value="Salir" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} />
          </>
        :
          <input type="button" value="Ingresar" onClick={auth.signinRedirect} />
        }
      </div>
    </div>
  );
}